package com.kitco.metalynxscrapit.shared.presentation.livemarket

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.presentation.common.formatted
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

data class LiveMarketUiState(
    val goldPrices: PricesUiState = PricesUiState(Metal.Gold),
    val silverPrices: PricesUiState = PricesUiState(Metal.Silver),
    val platinumPrices: PricesUiState = PricesUiState(Metal.Platinum),
)

data class PricesUiState(
    val metal: Metal,
    val marketPrice: MarketPriceUiState = MarketPriceUiState(),
    val londonFix: LondonFixUiState = LondonFixUiState()
)

data class MarketPriceUiState(
    val timestamp: String = Clock.System.now()
        .toLocalDateTime(TimeZone.currentSystemDefault()).formatted(),
    val bid: String = "-",
    val ask: String = "-",
    val high: String = "-",
    val low: String = "-",
    val change: Float = 0.0f,
    val changeText: String = "-",
    val changePercentage: String = "-",
    val measureUnit: MeasureUnit = MeasureUnit.Ounce,
    val currency: Currency = Currency.USD
)

data class LondonFixUiState(
    val timestamp: String = Clock.System.now()
        .toLocalDateTime(TimeZone.currentSystemDefault()).formatted("%1!tb %1!te"),
    val am: String = "-",
    val pm: String = "-",
    val measureUnit: MeasureUnit = MeasureUnit.Ounce,
    val currency: Currency = Currency.USD
)
