package com.kitco.metalynxscrapit.shared.data.repository.settings

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyAdjustments
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.MetalAdjustment
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.domain.model.PuritySettings
import com.russhwolf.settings.Settings
import com.russhwolf.settings.boolean
import com.russhwolf.settings.get
import com.russhwolf.settings.serialization.decodeValue
import com.russhwolf.settings.serialization.encodeValue
import com.russhwolf.settings.serialization.serializedValue
import com.russhwolf.settings.set

class ScrapCalculatorSettingsRepository(private val settings: Settings) {

    var currency: Currency by settings.serializedValue(
        Currency.serializer(),
        SettingsKey.Currency.name,
        Currency.USD
    )

    var measureUnit: MeasureUnit by settings.serializedValue(
        MeasureUnit.serializer(),
        SettingsKey.MeasureUnit.name,
        MeasureUnit.Ounce
    )

    var showWeightEntryColumn: Boolean by settings.boolean(
        SettingsKey.ShowWeightEntryColumn.name,
        true
    )

    var usdAdjustments: List<MetalAdjustment>
        get() = Metal.entries.map {
            settings.decodeValue(
                MetalAdjustment.serializer(),
                it.usdAdjustmentKey,
                MetalAdjustment(it)
            )
        }
        set(value) {
            value.forEach {
                settings.encodeValue(
                    MetalAdjustment.serializer(),
                    it.metal.usdAdjustmentKey,
                    it
                )
            }
        }

    var currencyAdjustments: List<CurrencyAdjustments>
        get() = Currency.entries.map {
            settings.decodeValue(
                CurrencyAdjustments.serializer(),
                it.currencyAdjustmentKey,
                CurrencyAdjustments.default(it)
            )
        }
        set(value) {
            value.forEach {
                settings.encodeValue(
                    CurrencyAdjustments.serializer(),
                    it.currency.currencyAdjustmentKey,
                    it
                )
            }
        }

    var puritySettings: List<PuritySettings>
        get() = (Purity.Gold.entries + Purity.Silver.entries + Purity.Platinum.entries)
            .map { purity ->
                val metal = when (purity) {
                    is Purity.Gold -> Metal.Gold
                    is Purity.Silver -> Metal.Silver
                    else -> Metal.Platinum
                }
                settings.decodeValue(
                    PuritySettings.serializer(),
                    purity.name,
                    PuritySettings(metal, purity)
                )
            }
        set(value) {
            value.forEach {
                val key = when (it.purity) {
                    is Purity.Gold -> it.purity.name
                    is Purity.Silver -> it.purity.name
                    is Purity.Platinum -> it.purity.name
                }
                settings.encodeValue(PuritySettings.serializer(), key, it)
            }
        }

    fun resetPurityWeights() {
        puritySettings = puritySettings.map { it.copy(weight = 1f) }
    }

    fun getCustomPrice(metal: Metal): Float? = settings.getFloatOrNull(metal.customPriceKey)

    fun setCustomPrice(metal: Metal, price: Float?) {
        settings[metal.customPriceKey] = price
    }

    fun clearCustomPrices() {
        Metal.entries.forEach { setCustomPrice(it, null) }
    }

    fun getCustomRate(): Float? = settings.getFloatOrNull(SettingsKey.CustomRate.name)

    fun setCustomRate(rate: Float?) {
        settings[SettingsKey.CustomRate.name] = rate
    }

    fun getCalculationPercent(metal: Metal): Float = settings[metal.calculationPercentKey, 100f]

    fun setCalculationPercent(metal: Metal, percent: Float) {
        settings[metal.calculationPercentKey] = percent
    }

    fun resetCalculationPercents() {
        Metal.entries.forEach { setCalculationPercent(it, 100f) }
    }
}
