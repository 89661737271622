package com.kitco.metalynxscrapit.shared.data.mapper

import com.kitco.metalynxscrapit.shared.data.dto.CurrencyDto
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyRate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant

fun CurrencyDto.toDomain() = CurrencyRate(
    Currency.entries.first { it.name == symbol },
    timestamp.toLocalDateTime().toInstant(TimeZone.UTC),
    ctoUSD
)
