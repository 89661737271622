package com.kitco.metalynxscrapit.shared.presentation

import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel

actual abstract class CoroutineViewModel {

    protected actual open val coroutineExceptionHandler =
        CoroutineExceptionHandler { _, throwable ->
            throwable.printStackTrace()
        }

    protected actual val coroutineScope by lazy {
        CoroutineScope(Dispatchers.Main + SupervisorJob() + coroutineExceptionHandler)
    }

    protected actual open fun onCleared() = Unit

    actual fun clear() {
        coroutineScope.cancel("ViewModel's clear called")
        onCleared()
    }
}
