package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.editPuritiesViewModel
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun TableHeaderViewEdit(title: String, editMode: MutableState<Boolean>) {
    Div(
        attrs = {
            classes(ComponentsStylesheet.TableHeaderContainer, CommonStyle.flexBetween)
        }
    ) {
        Span(
            attrs = {
                classes(ComponentsStylesheet.TitleTableHeader)
                style {
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Center)
                }
            }
        ) {
            Div(
                attrs = {
                    classes(ComponentsStylesheet.calculatorHeaderIcon)
                }
            )
            Text(title)
        }

        Div(
            attrs = { classes(CommonStyle.flexCentered) }
        ) {
            Span(
                attrs = {
                    classes(ComponentsStylesheet.EditBtnSection)
                }
            ) {
                ButtonDark(stringResource(SharedRes.strings.cancel), 16) {
                    editMode.value = false
                }
            }

            Span(
                attrs = {
                    classes(ComponentsStylesheet.EditBtnSection)
                }
            ) {
                ButtonDark(stringResource(SharedRes.strings.done), 16) {
                    editPuritiesViewModel.onDoneClick()
                    calculatorViewModel.refreshData(refreshPrices = false, refreshSetting = true)
                    editMode.value = false
                }
            }
        }
    }
}
