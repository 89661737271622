package com.kitco.metalynxscrapit.shared.domain.interactor

import com.kitco.metalynxscrapit.shared.domain.model.AdjustmentType
import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import kotlinx.datetime.Instant
import kotlinx.datetime.toLocalDateTime

fun Float.adjusted(value: Float, type: AdjustmentType) =
    this - if (type == AdjustmentType.Points) value else this * value / 100

fun Instant.inTimeZone(timeZone: AppTimeZone) = toLocalDateTime(timeZone.toKotlinTimeZone())
