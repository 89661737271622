package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import com.kitco.metalynxscrapit.shared.presentation.common.title
import com.kitco.scrapit.moreViewModel
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun TimeZoneSettings() {
    val state by moreViewModel.state.collectAsState()
    Div(
        attrs = {
            classes(CommonStyle.flexBetween)
            style {
                padding(18.px, 8.px)
                boxSizing("border-box")
            }
        }
    ) {
        TimeZoneButton(state.timeZone, AppTimeZone.Local)
        TimeZoneButton(state.timeZone, AppTimeZone.America)
        TimeZoneButton(state.timeZone, AppTimeZone.GMT)
    }
}

@Composable
private fun TimeZoneButton(selectedTimeZone: AppTimeZone, timeZone: AppTimeZone) {
    Div(
        attrs = {
            classes(CommonStyle.flexStart, ComponentsStylesheet.timeZoneItem)
            onClick {
                moreViewModel.onTimeZoneChange(timeZone)
            }
        }
    ) {
        Div(
            attrs = {
                classes(ComponentsStylesheet.timeZoneCheckbox, CommonStyle.flexCentered)
            }
        ) {
            if (selectedTimeZone == timeZone) {
                Div(
                    attrs = {
                        classes(ComponentsStylesheet.timeZoneCheckboxActive)
                    }
                )
            }
        }
        Span {
            Text(stringResource(timeZone.title))
        }
    }
}
