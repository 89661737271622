package com.kitco.scrapit.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.scrapit.components.TitleBarWidget
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.WidgetStyleSheet
import com.kitco.scrapit.view.WidgetStatusType
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun LiveMarket(widgetStatus: MutableState<WidgetStatusType>, transitionStatus: MutableState<Boolean>) {
    Div (
        attrs = {
            classes(WidgetStyleSheet.widgetInnerContainer)
        }
    ) {
        TitleBarWidget("Live Market", widgetStatus, transitionStatus)
    }
}