package com.kitco.scrapit.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.components.ButtonBase
import com.kitco.scrapit.components.ButtonDark
import com.kitco.scrapit.components.ModifyItem
import com.kitco.scrapit.components.TimeZoneSettings
import com.kitco.scrapit.components.TypeIcon
import com.kitco.scrapit.handlers.handleWidgetMove
import com.kitco.scrapit.priceAdjustmentsViewModel
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import com.kitco.scrapit.style.WidgetStyleSheet
import com.kitco.scrapit.view.WidgetStatusType
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun PriceAdjustments(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    val state by priceAdjustmentsViewModel.state.collectAsState()
    Div(
        attrs = {
            classes(WidgetStyleSheet.widgetInnerContainer)
        }
    ) {
        Div(
            attrs = {
                classes(WidgetStyleSheet.titleBarWidget, CommonStyle.flexBetween)
            }
        ) {
            Span(
                attrs = {
                    classes(WidgetStyleSheet.titleStyle)
                }
            ) {
                Text(stringResource(SharedRes.strings.price_adjustments))
            }
        }

        Div(
            attrs = {
                classes(WidgetStyleSheet.buttonsBar)
            }
        ) {
            Div(
                attrs = { classes(CommonStyle.flexCentered) }
            ) {
                Span(
                    attrs = {
                        classes(ComponentsStylesheet.EditBtnSection)
                    }
                ) {
                    ButtonDark(stringResource(SharedRes.strings.cancel), 14) {
                        handleWidgetMove(widgetStatus, WidgetStatusType.NONE, transitionStatus)
                        priceAdjustmentsViewModel.updateState()
                    }
                }

                Span(
                    attrs = {
                        classes(ComponentsStylesheet.EditBtnSection)
                    }
                ) {
                    ButtonDark(stringResource(SharedRes.strings.done), 14) {
                        handleWidgetMove(widgetStatus, WidgetStatusType.NONE, transitionStatus)
                        priceAdjustmentsViewModel.onDoneClicked()
                        calculatorViewModel.refreshData(
                            refreshPrices = false,
                            refreshSetting = true
                        )
                    }
                }
            }
        }

        Div(
            attrs = { classes(WidgetStyleSheet.resetBar, CommonStyle.flexBetween) }
        ) {
            Div({
                style {
                    width(70.percent)
                    fontSize(15.px)
                    color(Color.white)
                }
            }) {
                Text(stringResource(SharedRes.strings.adjustment_description, "USD"))
            }
            Div(
                attrs = {
                    classes(CommonStyle.flexEnd)
                    style { width(30.percent) }
                }
            ) {
                ButtonBase(
                    stringResource(SharedRes.strings.reset),
                    14,
                    TypeIcon.NONE,
                    null
                ) {
                    priceAdjustmentsViewModel.onResetClicked()
                }
            }
        }

        Div(
            attrs = {
                style { paddingBottom(5.px) }
            }
        ) {
            Div(
                attrs = {
                    classes(WidgetStyleSheet.tableHeader)
                }
            ) {
                Div(
                    attrs = {
                        classes(CommonStyle.flexStart)
                        style {
                            width(20.percent)
                            marginLeft(5.px)
                        }
                    }
                ) {
                    Text(stringResource(SharedRes.strings.metal))
                }
                Div(
                    attrs = {
                        classes(CommonStyle.flexStart)
                        style {
                            width(45.percent)
                            whiteSpace("pre-wrap")
                            justifyContent(JustifyContent.Center)
                            textAlign("center")
                        }
                    }
                ) {
                    Text(stringResource(SharedRes.strings.your_adjustments))
                }
                Div(
                    attrs = {
                        classes(CommonStyle.flexEnd)
                        style {
                            textAlign("end")
                            marginRight(5.px)
                            width(35.percent)
                        }
                    }
                ) {
                    Text(stringResource(SharedRes.strings.adjusted_price_usd, "USD"))
                }
            }

            state.usdAdjustments.forEach { s ->
                ModifyItem(
                    s.title,
                    s.adjustment,
                    "${s.currency}/${stringResource(SharedRes.strings.ounce_short)}",
                    s.adjustmentType,
                    s.adjustedPrice,
                    { priceAdjustmentsViewModel.onUsdAdjustmentChanged(s.metal, it) },
                    { priceAdjustmentsViewModel.onUsdAdjustmentTypeChanged(s.metal, it) }
                )
            }
        }

        if (state.exchangeRateAdjustment != null && state.currencyAdjustments != null) {
            Div(
                attrs = { classes(WidgetStyleSheet.resetBar, CommonStyle.flexBetween) }
            ) {
                Div({
                    style {
                        width(70.percent)
                        fontSize(15.px)
                        color(Color.white)
                    }
                }) {
                    Text(
                        stringResource(
                            SharedRes.strings.adjustment_exchange_rate_description,
                            state.exchangeRateAdjustment!!.currency.name
                        )
                    )
                }
                Div(
                    attrs = {
                        classes(CommonStyle.flexEnd)
                        style { width(30.percent) }
                    }
                ) {
                    Popup(stringResource(SharedRes.strings.exchange_rate_adjustment_info))
                }
            }

            Div(attrs = {
                style { paddingBottom(5.px) }
            }) {
                Div(
                    attrs = {
                        classes(WidgetStyleSheet.tableHeader)
                    }
                ) {
                    Div(
                        attrs = {
                            classes(CommonStyle.flexCentered)
                            style {
                                width(20.percent)
                            }
                        }
                    ) {}
                    Div(
                        attrs = {
                            classes(CommonStyle.flexStart)
                            style {
                                width(45.percent)
                                whiteSpace("pre-wrap")
                                justifyContent(JustifyContent.Center)
                                textAlign("center")
                            }
                        }
                    ) {
                        Text(stringResource(SharedRes.strings.your_adjustments))
                    }
                    Div(
                        attrs = {
                            classes(CommonStyle.flexCentered)
                            style {
                                textAlign("end")
                                marginRight(5.px)
                                width(35.percent)
                            }
                        }
                    ) {
                        Text(stringResource(SharedRes.strings.adjusted_exch_rate))
                    }
                }

                ModifyItem(
                    SharedRes.strings.exch_rate,
                    state.exchangeRateAdjustment!!.adjustment,
                    state.exchangeRateAdjustment!!.title,
                    state.exchangeRateAdjustment!!.adjustmentType,
                    state.exchangeRateAdjustment!!.adjustedRate,
                    { priceAdjustmentsViewModel.onExchangeRateAdjustmentChanged(it) },
                    { priceAdjustmentsViewModel.onExchangeRateAdjustmentTypeChanged(it) }
                )
            }

            Div(
                attrs = { classes(WidgetStyleSheet.resetBar, CommonStyle.flexBetween) }
            ) {
                Div({
                    style {
                        width(70.percent)
                        fontSize(15.px)
                        color(Color.white)
                    }
                }) {
                    Text(
                        stringResource(
                            SharedRes.strings.adjustment_description,
                            state.exchangeRateAdjustment!!.currency.name
                        )
                    )
                }
                Div(
                    attrs = {
                        classes(CommonStyle.flexEnd)
                        style { width(30.percent) }
                    }
                ) {
                    val currency = state.exchangeRateAdjustment!!.currency.name
                    Popup(
                        stringResource(
                            SharedRes.strings.currency_adjustment_info,
                            currency,
                            currency,
                            currency
                        )
                    )
                }
            }

            Div {
                Div(
                    attrs = {
                        classes(WidgetStyleSheet.tableHeader)
                    }
                ) {
                    Div(
                        attrs = {
                            classes(CommonStyle.flexStart)
                            style {
                                width(20.percent)
                                marginLeft(5.px)
                            }
                        }
                    ) {
                        Text(stringResource(SharedRes.strings.metal))
                    }
                    Div(
                        attrs = {
                            classes(CommonStyle.flexStart)
                            style {
                                width(45.percent)
                                whiteSpace("pre-wrap")
                                justifyContent(JustifyContent.Center)
                                textAlign("center")
                            }
                        }
                    ) {
                        Text(stringResource(SharedRes.strings.your_adjustments))
                    }
                    Div(
                        attrs = {
                            classes(CommonStyle.flexCentered)
                            style {
                                textAlign("end")
                                marginRight(5.px)
                                width(35.percent)
                            }
                        }
                    ) {
                        Text(
                            stringResource(
                                SharedRes.strings.adjusted_price_usd,
                                state.exchangeRateAdjustment!!.currency.name
                            )
                        )
                    }
                }

                state.currencyAdjustments!!.forEach { s ->
                    ModifyItem(
                        s.title,
                        s.adjustment,
                        "${s.currency}/${stringResource(SharedRes.strings.ounce_short)}",
                        s.adjustmentType,
                        s.adjustedPrice,
                        { priceAdjustmentsViewModel.onCurrencyAdjustmentChanged(s.metal, it) },
                        { priceAdjustmentsViewModel.onCurrencyAdjustmentTypeChanged(s.metal, it) }
                    )
                }
            }
        }

        Div(
            attrs = {
                classes(WidgetStyleSheet.innerBarWidget, CommonStyle.flexBetween)
            }
        ) {
            Span(
                attrs = {
                    classes(WidgetStyleSheet.titleStyleSm)
                }
            ) {
                Text(stringResource(SharedRes.strings.time_zone))
            }
        }

        TimeZoneSettings()
    }
}

@Composable
private fun Popup(text: String) {
    var popupVisible by remember { mutableStateOf(false) }
    Div(
        {
            style {
                width(25.px)
                height(25.px)
                position(Position.Relative)
                display(DisplayStyle.Flex)
                cursor("pointer")
                color(Color.darkgray)
                backgroundColor(Color.black)
                borderRadius(50.percent)
                property("user-select", "none")
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.Center)
            }
            onClick {
                popupVisible = !popupVisible
            }
        }
    ) {
        Text("?")
        if (popupVisible) {
            Div(
                {
                    style {
                        width(300.px)
                        background("#555")
                        color(Color.white)
                        backgroundColor(Color.gray)
                        textAlign("center")
                        borderRadius(6.px)
                        border {
                            style(LineStyle.Groove)
                            color(Color.white)
                        }
                        padding(8.px, 8.px)
                        position(Position.Absolute)
                        property("z-index", 1)
                        bottom(125.percent)
                        right((-50).percent)
                    }
                }
            ) {
                Text(text)
            }
        }
    }
}
