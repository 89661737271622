package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.presentation.common.DropDownItemState
import com.kitco.metalynxscrapit.shared.presentation.common.DropdownState
import com.kitco.metalynxscrapit.shared.presentation.common.icon
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.components.ButtonBase
import com.kitco.scrapit.components.DropDown
import com.kitco.scrapit.components.TypeIcon
import com.kitco.scrapit.priceAdjustmentsViewModel
import com.kitco.scrapit.style.AppStylesheet
import com.kitco.scrapit.style.CommonStyle
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.Div

@Composable
fun NavbarView(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    val state by calculatorViewModel.state.collectAsState()

    Div(
        attrs = { classes(CommonStyle.flexBetween, AppStylesheet.navbarContainer) }
    ) {
        Div({
            style {
                display(DisplayStyle.Flex)
            }
        }) {
            Div(
                attrs = { classes(CommonStyle.mr_15) }
            ) {
                ButtonBase(
                    title = stringResource(SharedRes.strings.refresh_prices),
                    titleSize = 18,
                    typeIcon = TypeIcon.NONE,
                    leadingIcon = SharedRes.images.ic_refresh
                ) {
                    calculatorViewModel.refreshData()
                }
            }
            Div(
                attrs = {
                    classes(CommonStyle.modify_wrapper_desktop)
                }
            ) {
                DropDown(
                    DropdownState(
                        selectedItem = state.currency.title,
                        items = Currency.entries.map {
                            DropDownItemState(
                                it.ordinal,
                                it.name,
                                it.icon
                            )
                        },
                        leadingIcon = state.currency.icon
                    ),
                    onItemClick = {
                        calculatorViewModel.onCurrencySelect(Currency.entries[it.id])
                        priceAdjustmentsViewModel.updateState()
                    }
                )
                DropDown(
                    DropdownState(
                        selectedItem = stringResource(state.measureUnit.title),
                        items = MeasureUnit.entries.map {
                            DropDownItemState(
                                it.ordinal,
                                it.name
                            )
                        }
                    ),
                    onItemClick = { calculatorViewModel.onMeasureUnitSelect(MeasureUnit.entries[it.id]) }
                )
            }

        }
        Div(
            attrs = {
                classes(CommonStyle.navbar_menu_desktop)
            }
        ) {
            // Commenting out the "Contact Us" button
            /*
            Div(
                attrs = { classes(CommonStyle.ml_10, CommonStyle.text_black) }
            ) {
                Button(
                    attrs = {
                        classes(
                            CommonStyle.btn_no_styled,
                            CommonStyle.fs_16,
                            if (widgetStatus.value == WidgetStatusType.CONTACT_US) CommonStyle.btn_active else CommonStyle.btn_inactive
                        )
                        onClick {
                            handleWidgetMove(
                                widgetStatus,
                                WidgetStatusType.CONTACT_US,
                                transitionStatus
                            )
                        }
                    }
                ) {
                    Text(stringResource(SharedRes.strings.contact_us))
                }
            }
            */

            // Commenting out the "More" button
            /*
            Div(
                attrs = { classes(CommonStyle.ml_10) }
            ) {
                Button(
                    attrs = {
                        classes(
                            CommonStyle.btn_no_styled,
                            CommonStyle.fs_16,
                            if (widgetStatus.value == WidgetStatusType.MORE) CommonStyle.btn_active else CommonStyle.btn_inactive
                        )
                        onClick {
                            handleWidgetMove(
                                widgetStatus,
                                WidgetStatusType.MORE,
                                transitionStatus
                            )
                        }
                    }
                ) {
                    Text(stringResource(SharedRes.strings.more))
                }
            }
            */
        }
    }
}
