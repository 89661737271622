package com.kitco.scrapit.style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexWrap
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.width

object WidgetStyleSheet : StyleSheet() {
    val widgetWrapperContainer by style {
        width(300.px)
        height(800.px)
        position(Position.Relative)
        boxSizing("border-box")
        backgroundImage("url('/bg_right_column.png')")
        backgroundRepeat("no-repeat")
        backgroundSize("center")
    }
    val asideContainer by style {
        backgroundColor(rgb(111, 111, 111))
        height(100.percent)
        width(100.percent)
        position(Position.Absolute)
    }
    val hideAside by style {
        left((-328).px)
    }
    val showAside by style {
        left(0.px)
    }
    val widgetInnerContainer by style {
        height(100.percent)
        width(100.percent)
        backgroundColor(rgb(227, 227, 227))
        borderRadius(5.px)
        boxSizing("border-box")
    }
    val titleBarWidget by style {
        width(100.percent)
        height(37.px)
        padding(0.px, 5.px)
        borderRadius(5.px, 5.px, 0.px, 0.px)
        backgroundImage("linear-gradient(#B1B1B1, #e8e8e8)")
    }
    val innerBarWidget by style {
        width(100.percent)
        height(30.px)
        padding(0.px, 5.px)
        marginTop(5.px)
        backgroundImage("linear-gradient(#B1B1B1, #e8e8e8)")
    }
    val titleStyle by style {
        fontSize(20.px)
        fontWeight(700)
    }
    val titleStyleSm by style {
        fontSize(18.px)
        fontWeight(400)
    }
    val titleBtn by style {
        width(26.px)
        height(26.px)
        borderRadius(50.percent)
        backgroundColor(rgb(227, 227, 227))
        cursor("pointer")
    }
    val contactUsInnerBox by style {
        paddingLeft(7.px)
        paddingTop(15.px)
        fontSize(15.px)
        boxSizing("border-box")
        lineHeight(18.px)
    }
    val linkStyle by style {
        color(rgb(51, 102, 255))
    }
    val moreHeader by style {
        height(27.px)
        width(100.percent)
        backgroundImage("linear-gradient(#e3e3e3, #FFFFFF)")
        fontSize(15.px)
        paddingLeft(5.px)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        boxSizing("border-box")
        fontWeight(700)

    }
    val moreContentWrapper by style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        width(100.percent)
        boxSizing("border-box")
    }
    val moreContentItem by style {
        width(33.percent)
        height(105.px)
        boxSizing("border-box")
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        justifyContent(JustifyContent.FlexStart)
        alignItems(AlignItems.Center)
        paddingTop(30.px)
        fontSize(12.px)
        cursor("pointer")
        textDecoration("none")
    }
    val blockImage by style {
        width(70.px)
        textAlign("center")
    }
    val buttonsBar by style {
        width(100.percent)
        height(40.px)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.End)
    }

    val resetBar by style {
        width(100.percent)
        padding(7.px, 5.px)
        backgroundColor(rgb(111, 111, 111))
    }

    val tableHeader by style {
        display(DisplayStyle.Flex)
        width(100.percent)
        fontSize(15.px)
        fontWeight(700)
        height(54.px)
        boxSizing("border-box")
    }

    val versionRow by style {
        fontSize(14.px)
        position(Position.Absolute)
        width(100.percent)
        textAlign("center")
    }

    val displayNone by style {
        display(DisplayStyle.None)
    }
}