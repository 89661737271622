package com.kitco.metalynxscrapit.shared.presentation.calculator.state

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.presentation.common.InputState
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.StringResource
import kotlinx.datetime.LocalDateTime

sealed interface ScrapCalculationTableUiState {

    data class Header(
        val metal: Metal,
        val title: StringResource,
        val priceInput: InputState,
        val timestamp: String? = null
    ) : ScrapCalculationTableUiState

    data class ExchangeRate(
        val currency: Currency,
        val currencyCode: String,
        val rateInput: InputState,
        val icon: ImageResource,
        val unadjustedRate: String,
        val timestamp: String? = null
    ) : ScrapCalculationTableUiState

    data class Description(
        val metal: Metal,
        val percent: String,
        val marketValue: String
    ) : ScrapCalculationTableUiState

    data class PurityItem(
        val metal: Metal,
        val purity: Purity,
        val purityTitle: StringResource,
        val weight: String,
        val measureUnit: StringResource,
        val result: String
    ) : ScrapCalculationTableUiState
}
