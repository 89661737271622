package com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel

import com.kitco.metalynxscrapit.shared.data.repository.settings.ScrapCalculatorSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.interactor.ScrapCalculatorInteractor
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.domain.model.PurityResult
import com.kitco.metalynxscrapit.shared.domain.model.PuritySettings
import com.kitco.metalynxscrapit.shared.domain.model.ScrapCalculationResult
import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculatorUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.toSelectorUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.toUiState
import com.kitco.metalynxscrapit.shared.presentation.common.decimalPartMoreThan
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class ScrapCalculatorViewModel(
    private val scrapCalculatorInteractor: ScrapCalculatorInteractor,
    private val settingsRepository: ScrapCalculatorSettingsRepository
) : CoroutineViewModel() {

    private val _state = MutableStateFlow(createInitialState())
    val state: StateFlow<ScrapCalculatorUiState> = _state.asStateFlow()

    init {
        refreshData(resetWeightsAndPercents = true)
    }

    fun refreshData(
        refreshPrices: Boolean = true,
        refreshSetting: Boolean = false,
        resetWeightsAndPercents: Boolean = false
    ) {
        coroutineScope.launch {
            _state.update { it.copy(loading = refreshPrices && resetWeightsAndPercents) }
            if (refreshPrices) {
                settingsRepository.clearCustomPrices()
                settingsRepository.setCustomRate(null)
            }
            if (resetWeightsAndPercents) {
                settingsRepository.resetCalculationPercents()
                settingsRepository.resetPurityWeights()
            }
            val gold =
                scrapCalculatorInteractor(
                    metal = Metal.Gold,
                    refreshPrices = refreshPrices,
                    refreshSettings = refreshSetting
                ).toUiState()
            val silver = scrapCalculatorInteractor(Metal.Silver).toUiState()
            val platinum = scrapCalculatorInteractor(Metal.Platinum).toUiState()
            _state.update {
                it.copy(
                    goldTable = gold,
                    silverTable = silver,
                    platinumTable = platinum,
                    loading = false
                )
            }
        }
    }

    fun onCurrencySelect(currency: Currency) {
        settingsRepository.currency = currency
        coroutineScope.launch {
            val gold = scrapCalculatorInteractor(Metal.Gold).toUiState()
            val silver = scrapCalculatorInteractor(Metal.Silver).toUiState()
            val platinum = scrapCalculatorInteractor(Metal.Platinum).toUiState()
            _state.update {
                it.copy(
                    currency = currency.toUiState(),
                    currencies = currency.toSelectorUiState(),
                    goldTable = gold,
                    silverTable = silver,
                    platinumTable = platinum
                )
            }
        }
    }

    fun onMeasureUnitSelect(measureUnit: MeasureUnit) {
        coroutineScope.launch {
            settingsRepository.measureUnit = measureUnit
            val gold = scrapCalculatorInteractor(Metal.Gold).toUiState()
            val silver = scrapCalculatorInteractor(Metal.Silver).toUiState()
            val platinum = scrapCalculatorInteractor(Metal.Platinum).toUiState()
            _state.update {
                it.copy(
                    measureUnit = measureUnit.toUiState(),
                    measureUnits = measureUnit.toSelectorUiState(),
                    goldTable = gold,
                    silverTable = silver,
                    platinumTable = platinum
                )
            }
        }
    }

    fun onMetalPriceChange(metal: Metal, price: String) {
        if (price.decimalPartMoreThan(2)) return
        coroutineScope.launch {
            settingsRepository.setCustomPrice(metal, price.toFloatOrNull() ?: 0f)
            val table = scrapCalculatorInteractor(metal).toUiState(price)
            when (metal) {
                Metal.Gold -> _state.update { it.copy(goldTable = table) }
                Metal.Silver -> _state.update { it.copy(silverTable = table) }
                Metal.Platinum -> _state.update { it.copy(platinumTable = table) }
            }
        }
    }

    fun onCurrencyRateChange(rate: String) {
        coroutineScope.launch {
            settingsRepository.setCustomRate(rate.toFloatOrNull() ?: 0f)
            val gold = scrapCalculatorInteractor(Metal.Gold).toUiState(customRate = rate)
            val silver = scrapCalculatorInteractor(Metal.Silver).toUiState(customRate = rate)
            val platinum = scrapCalculatorInteractor(Metal.Platinum).toUiState(customRate = rate)
            _state.update {
                it.copy(
                    goldTable = gold,
                    silverTable = silver,
                    platinumTable = platinum
                )
            }
        }
    }

    fun onPercentChange(metal: Metal, percent: String) {
        if (percent.decimalPartMoreThan(2)) return
        coroutineScope.launch {
            settingsRepository.setCalculationPercent(metal, percent.toFloatOrNull() ?: 0f)
            val table = scrapCalculatorInteractor(metal).toUiState(customPercent = percent)
            when (metal) {
                Metal.Gold -> _state.update { it.copy(goldTable = table) }
                Metal.Silver -> _state.update { it.copy(silverTable = table) }
                Metal.Platinum -> _state.update { it.copy(platinumTable = table) }
            }
        }
    }

    fun onWeightChange(metal: Metal, purity: Purity, weight: String) {
        coroutineScope.launch {
            settingsRepository.puritySettings =
                listOf(PuritySettings(metal, purity, weight = weight.toFloatOrNull() ?: 0f))
            val table = scrapCalculatorInteractor(metal)
                .toUiState(customWeight = Triple(metal, purity, weight))
            when (metal) {
                Metal.Gold -> _state.update { it.copy(goldTable = table) }
                Metal.Silver -> _state.update { it.copy(silverTable = table) }
                Metal.Platinum -> _state.update { it.copy(platinumTable = table) }
            }
        }
    }

    private fun createInitialState(): ScrapCalculatorUiState {
        val currency = settingsRepository.currency
        val measureUnit = settingsRepository.measureUnit
        return ScrapCalculatorUiState(
            currency.toUiState(),
            currency.toSelectorUiState(),
            measureUnit.toUiState(),
            measureUnit.toSelectorUiState(),
            createInitialTableState(Metal.Gold, measureUnit, currency),
            createInitialTableState(Metal.Silver, measureUnit, currency),
            createInitialTableState(Metal.Platinum, measureUnit, currency),
        )
    }

    private fun createInitialTableState(
        metal: Metal,
        measureUnit: MeasureUnit,
        currency: Currency
    ): List<ScrapCalculationTableUiState> {
        val purityEntries = when (metal) {
            Metal.Gold -> Purity.Gold.entries
            Metal.Silver -> Purity.Silver.entries
            Metal.Platinum -> Purity.Platinum.entries
        }
        return ScrapCalculationResult(
            metal,
            0f,
            100f,
            measureUnit,
            currency,
            0f,
            0f,
            0f,
            purityEntries.map { PurityResult(it, 0f, 1f) }
        ).toUiState()
    }
}
