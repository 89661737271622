package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import com.kitco.scrapit.style.ComponentsStylesheet
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun ButtonDark(title: String, titleSize: Number, onclick: () -> Unit) {
    Button(
        attrs = {
            classes(ComponentsStylesheet.ButtonDark)
            onClick { onclick() }
        }
    ) {
        Span({
            style {
                fontSize(titleSize.px)
                color(Color.white)
            }
        }) {
            Text(title)
        }
    }
}