package com.kitco.metalynxscrapit.shared.data.repository.settings

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import kotlinx.datetime.LocalDate

enum class SettingsKey {
    ShowWeightEntryColumn,
    Currency,
    MeasureUnit,
    CustomPrice,
    CustomRate,
    CalculationPercent,
    UsdAdjustment,
    CurrencyAdjustment,

    MarketPriceMeasureUnit,
    MarketPriceCurrency,
    LondonFixMeasureUnit,
    LondonFixCurrency,

    Language,
    TimeZone,

    PreciousMetals,
    Currencies,
    LondonFix
}

val Metal.customPriceKey get() = this.name + SettingsKey.CustomPrice.name
val Metal.calculationPercentKey get() = this.name + SettingsKey.CalculationPercent.name
val Metal.usdAdjustmentKey get() = this.name + SettingsKey.UsdAdjustment.name
val Metal.marketPriceMeasureUnitKey get() = this.name + SettingsKey.MarketPriceMeasureUnit.name
val Metal.marketPriceCurrencyKey get() = this.name + SettingsKey.MarketPriceCurrency.name
val Metal.londonFixMeasureUnitKey get() = this.name + SettingsKey.LondonFixMeasureUnit.name
val Metal.londonFixCurrencyKey get() = this.name + SettingsKey.LondonFixCurrency.name

val Currency.currencyAdjustmentKey get() = this.name + SettingsKey.CurrencyAdjustment.name

val LocalDate.londonFixKey get() = SettingsKey.LondonFix.name + this.toString()
