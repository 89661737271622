package com.kitco.metalynxscrapit.shared.data.repository

import com.kitco.metalynxscrapit.shared.data.api.PricesApi
import com.kitco.metalynxscrapit.shared.data.datasource.PricesLocalDataSource
import com.kitco.metalynxscrapit.shared.data.dto.CurrencyDto
import com.kitco.metalynxscrapit.shared.data.dto.LondonFixValueDto
import com.kitco.metalynxscrapit.shared.data.dto.PreciousMetalDto
import com.kitco.metalynxscrapit.shared.data.mapper.toDomain
import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyRate
import com.kitco.metalynxscrapit.shared.domain.model.LondonFix
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.MetalPrice
import io.ktor.client.network.sockets.SocketTimeoutException
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.minus
import kotlinx.datetime.toLocalDateTime

class PricesRepository(
    private val pricesApi: PricesApi,
    private val pricesLocalDataSource: PricesLocalDataSource
) {

    suspend fun getPreciousMetals(forceRefresh: Boolean = false): List<MetalPrice> {
        val symbols = Metal.entries.joinToString(",") { it.code }

        val getAndUpdate = suspend {
            kotlin.runCatching {
                pricesApi.getPreciousMetals(
                    symbols,
                    Currency.USD.name,
                    MeasureUnit.Ounce.name.uppercase(),
                    AppTimeZone.GMT.zoneId,
                    "no"
                ).list.map(PreciousMetalDto::toDomain)
                    .also { pricesLocalDataSource.setPreciousMetals(it) }
            }.recover {
                pricesLocalDataSource.getPreciousMetals() ?: throw it
            }.getOrThrow()
        }

        return if (forceRefresh) getAndUpdate()
        else pricesLocalDataSource.getPreciousMetals() ?: getAndUpdate()
    }

    suspend fun getLivePreciousMetal(
        metal: Metal,
        currency: Currency,
        measureUnit: MeasureUnit
    ): MetalPrice = pricesApi.getPreciousMetal(
        metal.code,
        currency.name,
        measureUnit.name.uppercase(),
        AppTimeZone.GMT.zoneId,
        "no"
    ).item.let(PreciousMetalDto::toDomain)

    suspend fun getCurrencies(forceRefresh: Boolean = false): List<CurrencyRate> {
        val symbols = Currency.entries.joinToString(",") { it.name }

        val getAndUpdate = suspend {
            kotlin.runCatching {
                pricesApi.getCurrencies(
                    symbols,
                    AppTimeZone.GMT.zoneId
                ).list.map(CurrencyDto::toDomain)
                    .also { pricesLocalDataSource.setCurrencies(it) }
            }.recover {
                pricesLocalDataSource.getCurrencies() ?: throw it
            }.getOrThrow()
        }

        return if (forceRefresh) getAndUpdate()
        else pricesLocalDataSource.getCurrencies() ?: getAndUpdate()
    }

    suspend fun getLatestLondonFix(metal: Metal, currency: Currency): LondonFix {
        val startDate = Clock.System.now()
            .toLocalDateTime(kotlinx.datetime.TimeZone.currentSystemDefault()).date

        for (i in 0..5) {
            val date = startDate.minus(DatePeriod(days = i))
            val result = kotlin.runCatching {
                pricesApi.getLondonFix(
                    metal.code,
                    currency.name,
                    date.toString()
                ).value.let(LondonFixValueDto::toDomain)
            }
            if (result.exceptionOrNull() is SocketTimeoutException)
                throw Exception("Can't get latest london fix value")

            if (result.isSuccess) return result.getOrThrow()
        }

        throw Exception("Can't get latest london fix value")
    }
}
