package com.kitco.metalynxscrapit.shared.di

import com.kitco.metalynxscrapit.shared.data.api.FeedbackApi
import com.kitco.metalynxscrapit.shared.data.api.PricesApi
import com.kitco.metalynxscrapit.shared.data.datasource.PricesLocalDataSource
import com.kitco.metalynxscrapit.shared.data.repository.PricesRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.AppSettingsRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.LiveMarketSettingsRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.ScrapCalculatorSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.interactor.LiveLondonFixInteractor
import com.kitco.metalynxscrapit.shared.domain.interactor.LiveMetalPriceInteractor
import com.kitco.metalynxscrapit.shared.domain.interactor.ScrapCalculatorInteractor
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.EditPuritiesViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.PriceAdjustmentsViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.ScrapCalculatorViewModel
import com.kitco.metalynxscrapit.shared.presentation.feedback.FeedbackViewModel
import com.kitco.metalynxscrapit.shared.presentation.livemarket.LiveMarketViewModel
import com.kitco.metalynxscrapit.shared.presentation.more.MoreViewModel
import com.russhwolf.settings.Settings
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.http.ContentType
import io.ktor.http.URLProtocol
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import org.koin.core.context.startKoin
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.KoinAppDeclaration
import org.koin.dsl.module

fun initKoin(appDeclaration: KoinAppDeclaration = {}) =
    startKoin {
        appDeclaration()
        modules(
            viewModelModule,
            repositoryModule,
            interactorModule,
            settingsModule,
            dataSourceModule,
            apiModule,
            platformModule(),
            jsonModule,
            networkModule,
        )
    }

// called by iOS etc
@Suppress("unused")
fun initKoin() = initKoin {}

private val viewModelModule = module {
    factoryOf(::ScrapCalculatorViewModel)
    factoryOf(::PriceAdjustmentsViewModel)
    factoryOf(::EditPuritiesViewModel)
    factoryOf(::LiveMarketViewModel)
    factoryOf(::MoreViewModel)
    factoryOf(::FeedbackViewModel)
}

private val repositoryModule = module {
    singleOf(::ScrapCalculatorSettingsRepository)
    singleOf(::LiveMarketSettingsRepository)
    singleOf(::AppSettingsRepository)
    singleOf(::PricesRepository)
}

private val interactorModule = module {
    singleOf(::ScrapCalculatorInteractor)
    singleOf(::LiveMetalPriceInteractor)
    singleOf(::LiveLondonFixInteractor)
}

private val dataSourceModule = module {
    singleOf(::PricesLocalDataSource)
}

private val apiModule = module {
    singleOf(::PricesApi)
    singleOf(::FeedbackApi)
}

private val settingsModule = module {
    singleOf(::Settings)
}

private val jsonModule = module {
    single {
        Json {
            ignoreUnknownKeys = true
            prettyPrint = true
        }
    }
}

private const val HOST = "kds2.kitco.com"
const val API_KEY = "9bnteWVi2kT13528d100c608fn0TlbC6"

private val networkModule = module {
    single {
        HttpClient(get()) {
            defaultRequest {
                url {
                    protocol = URLProtocol.HTTPS
                    host = HOST
                }
            }
            install(HttpTimeout) {
                requestTimeoutMillis = 15_000
            }
            install(ContentNegotiation) {
                json(get(), contentType = ContentType.Any)
            }
            install(Logging) {
                level = LogLevel.ALL
                logger = object : Logger {
                    override fun log(message: String) {
                        co.touchlab.kermit.Logger.d("HTTP") { message }
                    }
                }
            }
        }
    }
}
