package com.kitco.metalynxscrapit.shared.presentation.calculator.state

data class ScrapCalculatorUiState(
    val currency: CurrencyUiState,
    val currencies: List<CurrencyUiState>,
    val measureUnit: MeasureUnitUiState,
    val measureUnits: List<MeasureUnitUiState>,
    val goldTable: List<ScrapCalculationTableUiState>,
    val silverTable: List<ScrapCalculationTableUiState>,
    val platinumTable: List<ScrapCalculationTableUiState>,
    val loading: Boolean = false
)
