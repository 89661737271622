package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.editPuritiesViewModel
import com.kitco.scrapit.style.AppStylesheet
import org.jetbrains.compose.web.dom.Div

@Composable
fun CalculatorView(editMode: MutableState<Boolean>) {
    val calculatorState by calculatorViewModel.state.collectAsState()
    val editPuritiesState by editPuritiesViewModel.state.collectAsState()

    if (!editMode.value) {
        Div(
            attrs = { classes(AppStylesheet.calculatorContainer) }
        ) {
            TableView(calculatorState.goldTable, editMode)
            TableView(calculatorState.silverTable, editMode)
            TableView(calculatorState.platinumTable, editMode)
        }
    } else {
        Div(
            attrs = { classes(AppStylesheet.calculatorContainer) }
        ) {
            TableViewEdit(Metal.Gold, editPuritiesState.gold, editMode)
            TableViewEdit(Metal.Silver, editPuritiesState.silver, editMode)
            TableViewEdit(Metal.Platinum, editPuritiesState.platinum, editMode)
        }
    }
}
