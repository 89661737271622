package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kitco.metalynxscrapit.shared.presentation.common.DropDownItemState
import com.kitco.metalynxscrapit.shared.presentation.common.DropdownState
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun DropDown(state: DropdownState, onItemClick: (DropDownItemState) -> Unit, titleSize: Int = 18) {
    var expanded by remember { mutableStateOf(false) }
    Div(
        attrs = {
            classes(CommonStyle.mr_15)
            style { position(Position.Relative) }
        }
    ) {
        ButtonBase(state.selectedItem, titleSize, TypeIcon.BASE, state.leadingIcon) {
            expanded = !expanded
        }

        if (expanded) {
            DisposableEffect(Unit) {
                window.onclick = {
                    expanded = false
                    it
                }
                onDispose { window.onclick = null }
            }

            Div(
                attrs = {
                    classes(CommonStyle.dialogContainer, ComponentsStylesheet.CurrencyContainer)
                }
            ) {
                state.items.forEach {
                    Div(
                        attrs = {
                            classes(
                                ComponentsStylesheet.DialogRowStyle,
                                ComponentsStylesheet.currencyItem
                            )
                            onClick { _ ->
                                expanded = false
                                onItemClick(it)
                            }
                        }
                    ) {
                        if (it.leadingIcon != null) {
                            Img(it.leadingIcon!!.fileUrl) {
                                style {
                                    marginRight(5.px)
                                }
                            }
                        }
                        Div (
                            attrs = {
                                classes(CommonStyle.calcItemFontSz)
                            }
                        ) {
                            Text(it.title)
                        }
                    }
                }
            }
        }
    }
}
