package com.kitco.scrapit.handlers

import androidx.compose.runtime.MutableState
import com.kitco.scrapit.view.WidgetStatusType
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


fun handleWidgetMove(
    currentStatus: MutableState<WidgetStatusType>,
    nextStatus: WidgetStatusType,
    transitionStatus: MutableState<Boolean>
) {
    GlobalScope.launch {
        if (currentStatus.value == WidgetStatusType.NONE) {
            currentStatus.value = nextStatus
        } else if (nextStatus == WidgetStatusType.NONE) {
            transitionStatus.value = true
            delay(1200)
            currentStatus.value = nextStatus
            transitionStatus.value = false
        } else if (currentStatus.value !== WidgetStatusType.NONE && currentStatus.value !== nextStatus) {
            transitionStatus.value = true
            delay(1200)
            currentStatus.value = nextStatus
            transitionStatus.value = false
        }
    }
}
