package com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel

import com.kitco.metalynxscrapit.shared.data.repository.settings.ScrapCalculatorSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.domain.model.PuritySettings
import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.EditPuritiesUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.PurityItemUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.toDomain
import com.kitco.metalynxscrapit.shared.presentation.calculator.toUiState
import com.kitco.metalynxscrapit.shared.presentation.common.toString
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update

class EditPuritiesViewModel(
    private val scrapCalculatorSettingsRepository: ScrapCalculatorSettingsRepository
) : CoroutineViewModel() {

    private val _state = MutableStateFlow(createInitialState())
    val state: StateFlow<EditPuritiesUiState> = _state.asStateFlow()

    fun onDoneClick() {
        scrapCalculatorSettingsRepository.puritySettings =
            (state.value.gold + state.value.silver + state.value.platinum)
                .map(PurityItemUiState::toDomain)
    }

    fun onResetClick(metal: Metal) {
        val transform = { purity: Purity ->
            PuritySettings(metal, purity).toUiState()
        }
        _state.update { s ->
            when (metal) {
                Metal.Gold -> s.copy(gold = Purity.Gold.entries.map(transform))
                Metal.Silver -> s.copy(silver = Purity.Silver.entries.map(transform))
                Metal.Platinum -> s.copy(platinum = Purity.Platinum.entries.map(transform))
            }
        }
    }

    fun onCheckedChange(metal: Metal, purity: Purity) {
        val transform = { item: PurityItemUiState ->
            if (item.purity != purity) item
            else item.copy(enabled = !item.enabled)
        }
        _state.update { s ->
            when (metal) {
                Metal.Gold -> s.copy(gold = s.gold.map(transform))
                Metal.Silver -> s.copy(silver = s.silver.map(transform))
                Metal.Platinum -> s.copy(platinum = s.platinum.map(transform))
            }
        }
    }

    fun onPurityChange(metal: Metal, purity: Purity, value: String) {
        val transform = { item: PurityItemUiState ->
            if (item.purity != purity) item
            else item.copy(
                purityValue = value,
                purityPercent = ((value.toFloatOrNull() ?: 0f) * 100).toString(2)
            )
        }
        _state.update { s ->
            when (metal) {
                Metal.Gold -> s.copy(gold = s.gold.map(transform))
                Metal.Silver -> s.copy(silver = s.silver.map(transform))
                Metal.Platinum -> s.copy(platinum = s.platinum.map(transform))
            }
        }
    }

    private fun createInitialState(): EditPuritiesUiState {
        val puritySettings = scrapCalculatorSettingsRepository.puritySettings
        return EditPuritiesUiState(
            gold = puritySettings.filter { it.metal == Metal.Gold }.map(PuritySettings::toUiState),
            silver = puritySettings.filter { it.metal == Metal.Silver }
                .map(PuritySettings::toUiState),
            platinum = puritySettings.filter { it.metal == Metal.Platinum }
                .map(PuritySettings::toUiState)
        )
    }
}
