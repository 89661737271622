package com.kitco.metalynxscrapit.shared.domain.interactor

import com.kitco.metalynxscrapit.shared.data.repository.PricesRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.AppSettingsRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.LiveMarketSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.model.LiveLondonFix
import com.kitco.metalynxscrapit.shared.domain.model.Metal

class LiveLondonFixInteractor(
    private val pricesRepository: PricesRepository,
    private val settingsRepository: LiveMarketSettingsRepository,
    private val appSettingsRepository: AppSettingsRepository
) {

    suspend operator fun invoke(metal: Metal): LiveLondonFix {
        val measureUnit = settingsRepository.getLondonFixMeasureUnit(metal)
        val currency = settingsRepository.getLondonFixCurrency(metal)

        val londonFix = pricesRepository.getLatestLondonFix(metal, currency)

        return LiveLondonFix(
            londonFix.timestamp.inTimeZone(appSettingsRepository.timeZone),
            londonFix.amPrice * measureUnit.ounce,
            londonFix.pmPrice?.times(measureUnit.ounce),
            measureUnit,
            currency
        )
    }
}
