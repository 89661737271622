package com.kitco.metalynxscrapit.shared.data.mapper

import com.kitco.metalynxscrapit.shared.data.dto.PreciousMetalDto
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.MetalPrice
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant

fun PreciousMetalDto.toDomain() = MetalPrice(
    metal = symbol.toMetal(),
    measureUnit = MeasureUnit.entries.first { it.name.uppercase() == unit },
    currency = Currency.USD,
    timestamp = timestamp.toLocalDateTime().toInstant(TimeZone.UTC),
    mid = mid,
    bid = bid,
    ask = ask,
    high = high,
    low = low,
    change = change,
    changePercentage = changePercentage
)
