package com.kitco.metalynxscrapit.shared.data.api

import com.kitco.metalynxscrapit.shared.data.dto.CurrenciesDto
import com.kitco.metalynxscrapit.shared.data.dto.LondonFixDto
import com.kitco.metalynxscrapit.shared.data.dto.MultipleMetalsDto
import com.kitco.metalynxscrapit.shared.data.dto.SingleMetalDto
import com.kitco.metalynxscrapit.shared.di.API_KEY
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.http.URLBuilder

class PricesApi(private val httpClient: HttpClient) {

    private fun URLBuilder.defaultParams() {
        parameters.append("apikey", API_KEY)
        parameters.append("type", "json")
        parameters.append("df", "1")
        parameters.append("tf", "1")
    }

    suspend fun getPreciousMetals(
        symbols: String,
        currency: String,
        unit: String,
        timeZone: String,
        kgx: String
    ): MultipleMetalsDto = httpClient.get("getPM") {
        url {
            defaultParams()
            parameters.append("symbol", symbols)
            parameters.append("currency", currency)
            parameters.append("unit", unit)
            parameters.append("tz", timeZone)
            parameters.append("kgx", kgx)
        }
    }.body()

    suspend fun getPreciousMetal(
        symbols: String,
        currency: String,
        unit: String,
        timeZone: String,
        kgx: String
    ): SingleMetalDto = httpClient.get("getPM") {
        url {
            defaultParams()
            parameters.append("symbol", symbols)
            parameters.append("currency", currency)
            parameters.append("unit", unit)
            parameters.append("tz", timeZone)
            parameters.append("kgx", kgx)
        }
    }.body()

    suspend fun getCurrencies(
        symbols: String,
        timeZone: String
    ): CurrenciesDto = httpClient.get("getUSD?ver=2.0") {
        url {
            defaultParams()
            parameters.append("symbol", symbols)
            parameters.append("tz", timeZone)
        }
    }.body()

    /**
     * @param date in format yyyy-MM-dd (for example 2018-06-04)
     */
    suspend fun getLondonFix(
        symbol: String,
        currency: String,
        date: String
    ): LondonFixDto = httpClient.get("getLFValue") {
        url {
            defaultParams()
            parameters.append("symbol", symbol)
            parameters.append("currency", currency)
            parameters.append("date", date)
        }
    }.body()
}
