package com.kitco.metalynxscrapit.shared.presentation.common

import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.Language
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import kotlinx.datetime.LocalDateTime
import net.sergeych.sprintf.sprintf

val Currency.icon
    get() = when (this) {
        Currency.USD -> SharedRes.images.us
        Currency.CAD -> SharedRes.images.ca
        Currency.INR -> SharedRes.images.inr
        Currency.AUD -> SharedRes.images.au
        Currency.EUR -> SharedRes.images.eu
        Currency.GBP -> SharedRes.images.uk
        Currency.JPY -> SharedRes.images.jp
        Currency.CHF -> SharedRes.images.ch
        Currency.ZAR -> SharedRes.images.za
        Currency.CNY -> SharedRes.images.cn
        Currency.BRL -> SharedRes.images.br
        Currency.MXN -> SharedRes.images.mx
        Currency.HKD -> SharedRes.images.hk
        Currency.RUB -> SharedRes.images.ru
    }

val Currency.symbol
    get() = when (this) {
        Currency.USD -> "$"
        Currency.CAD -> "$"
        Currency.INR -> "₹"
        Currency.AUD -> "$"
        Currency.EUR -> "€"
        Currency.GBP -> "£"
        Currency.JPY -> "$"
        Currency.CHF -> "¥"
        Currency.ZAR -> "ZAR"
        Currency.CNY -> "R"
        Currency.BRL -> "¥"
        Currency.MXN -> "R$"
        Currency.HKD -> "$"
        Currency.RUB -> "руб"
    }

val MeasureUnit.title
    get() = when (this) {
        MeasureUnit.Ounce -> SharedRes.strings.ounce_base
        MeasureUnit.Gram -> SharedRes.strings.gram_base
        MeasureUnit.Kilo -> SharedRes.strings.kilo_base
        MeasureUnit.DWT -> SharedRes.strings.dwt_base
        MeasureUnit.TOLA -> SharedRes.strings.tola_base
        MeasureUnit.TAEL -> SharedRes.strings.tael_base
    }

val MeasureUnit.shortTitle
    get() = when (this) {
        MeasureUnit.Ounce -> SharedRes.strings.ounce_short
        MeasureUnit.Gram -> SharedRes.strings.gram_short
        MeasureUnit.Kilo -> SharedRes.strings.kilo_short
        MeasureUnit.DWT -> SharedRes.strings.dwt_base
        MeasureUnit.TOLA -> SharedRes.strings.tola_base
        MeasureUnit.TAEL -> SharedRes.strings.tael_base
    }

val Purity.title
    get() = when (this) {
        Purity.Gold.GOLD_9K -> SharedRes.strings.gold_purity_9K
        Purity.Gold.GOLD_10K -> SharedRes.strings.gold_purity_10K
        Purity.Gold.GOLD_12K -> SharedRes.strings.gold_purity_12K
        Purity.Gold.GOLD_14K -> SharedRes.strings.gold_purity_14K
        Purity.Gold.GOLD_16K -> SharedRes.strings.gold_purity_16K
        Purity.Gold.GOLD_18K -> SharedRes.strings.gold_purity_18K
        Purity.Gold.GOLD_21K -> SharedRes.strings.gold_purity_21K
        Purity.Gold.COIN_900 -> SharedRes.strings.gold_purity_900
        Purity.Gold.COIN_22K -> SharedRes.strings.gold_purity_coin_22K
        Purity.Gold.GOLD_22K -> SharedRes.strings.gold_purity_22K
        Purity.Gold.GOLD_24K -> SharedRes.strings.gold_purity_24K
        Purity.Platinum.PLATINUM_100 -> SharedRes.strings.platinum_purity_pure
        Purity.Platinum.PLATINUM_90 -> SharedRes.strings.platinum_purity_90
        Purity.Silver.SILVER_1000 -> SharedRes.strings.silver_purity_pure
        Purity.Silver.SILVER_925 -> SharedRes.strings.silver_purity_sterling
        Purity.Silver.SILVER_800 -> SharedRes.strings.silver_purity_800
        Purity.Silver.SILVER_600 -> SharedRes.strings.silver_purity_600
        Purity.Silver.SILVER_400 -> SharedRes.strings.silver_purity_400
    }

val Metal.title
    get() = when (this) {
        Metal.Gold -> SharedRes.strings.gold
        Metal.Silver -> SharedRes.strings.silver
        Metal.Platinum -> SharedRes.strings.platinum
    }

val Language.title
    get() = when (this) {
        Language.English -> "English"
        Language.French -> "Français"
    }

val AppTimeZone.title
    get() = when (this) {
        AppTimeZone.America -> SharedRes.strings.ny_time
        AppTimeZone.GMT -> SharedRes.strings.gmt
        AppTimeZone.Local -> SharedRes.strings.local
    }

fun Float.toString(decimals: Int, ignoreZeroDecimalPart: Boolean = false) = when {
    ignoreZeroDecimalPart && toInt() - this == 0f -> toInt().toString()
    decimals == 0 -> toString()
    else -> "%.${decimals}f".sprintf(this)
}

fun LocalDateTime.formatted(format: String = "%1!tb %1!te, %1!tY\n%1!tR") = format.sprintf(this)
