package com.kitco.scrapit

import com.kitco.metalynxscrapit.shared.di.initKoin
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.EditPuritiesViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.PriceAdjustmentsViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel.ScrapCalculatorViewModel
import com.kitco.metalynxscrapit.shared.presentation.more.MoreViewModel
import com.kitco.scrapit.style.AppStylesheet
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import com.kitco.scrapit.style.WidgetStyleSheet
import com.kitco.scrapit.view.WrapperMainView
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable

val koin = initKoin().koin
val calculatorViewModel = koin.get<ScrapCalculatorViewModel>()
val priceAdjustmentsViewModel = koin.get<PriceAdjustmentsViewModel>()
val editPuritiesViewModel = koin.get<EditPuritiesViewModel>()
val moreViewModel = koin.get<MoreViewModel>()

fun main() {
    renderComposable(rootElementId = "root") {
        Style(AppStylesheet)
        Style(CommonStyle)
        Style(WidgetStyleSheet)
        Style(ComponentsStylesheet)

        WrapperMainView()
    }
}
