package com.kitco.scrapit.style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.opacity
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.width

object CommonStyle : StyleSheet() {
    val ml_10 by style {
        marginLeft(10.px)
    }
    val mr_15 by style {
        marginRight(15.px)
    }
    val mr_10 by style {
        marginRight(10.px)
    }
    val fs_16 by style {
        fontSize(16.px)
    }
    val width_30 by style {
        width(30.percent)
    }
    val width_20 by style {
        width(20.percent)
    }
    val text_black by style {
        color(rgb(54,54,54))
    }
    val flexCentered by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
        boxSizing("border-box")
    }
    val flexBetween by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.SpaceBetween)
        boxSizing("border-box")
    }
    val flexEnd by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.End)
        boxSizing("border-box")
    }
    val flexStart by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Start)
        boxSizing("border-box")
    }
    val btn_no_styled by style {
        border(0.px)
        backgroundColor(Color.transparent)
        cursor("pointer")
    }
    val btn_active by style {
        color(rgb(244, 116, 2))
    }
    val btn_inactive by style {
        color(rgb(0, 0, 0))
    }
    val textInputStyle by style {
        height(25.px)
        borderRadius(5.px)
        marginRight(5.px)
        border(1.px, LineStyle.Solid, rgba(180, 180, 180, 1))
        paddingRight(3.px)
        paddingLeft(3.px)
    }
    val checkBoxStyle by style {
        width(20.px)
        height(20.px)
        position(Position.Absolute)
        cursor("pointer")
        opacity(0)
    }
    val customCheckBox by style {
        display(DisplayStyle.Block)
    }
    val customCheckBoxChecked by style {
        display(DisplayStyle.Block)
    }
    val dialogContainer by style {
        position(Position.Absolute)
        width(200.px)
        // height(202.px)
        maxHeight(202.px)
        backgroundColor(rgb(237, 237,237))
        marginTop(2.px)
        right(0.px)
    }
    val imageBtnContainer by style {
        position(Position.Absolute)
        width(10.px)
        height(10.px)
        backgroundImage("url('/drop_pic.png')")
        backgroundRepeat("no-repeat")
        bottom(0.px)
        right(0.px)
    }
    val navbar_menu_desktop by style {
        display(DisplayStyle.Flex)
    }
    val modify_wrapper_desktop by style {
       cursor("pointer")
    }
    val modify_wrapper_mobile by style {
        cursor("pointer")
    }
    val lang_btn_mobile by style {
        cursor("pointer")
    }
    val flag_image by style {
        marginRight(5.px)
    }
    val calcItemInput by style {
        width(100.px)
    }
    val calcInputHeader by style {
        width(70.px)
    }
    val calcInputLabel by style {
        paddingLeft(15.px)
    }
    val calcInputWrap by style {
        width(30.percent)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.Start)
        alignItems(AlignItems.End)
        paddingLeft(10.px)
    }
    val calcItemFontSz by style {
        fontSize(14.px)
    }
}
