package com.kitco.metalynxscrapit.shared.presentation.livemarket

import com.kitco.metalynxscrapit.shared.data.repository.settings.LiveMarketSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.interactor.LiveLondonFixInteractor
import com.kitco.metalynxscrapit.shared.domain.interactor.LiveMetalPriceInteractor
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class LiveMarketViewModel(
    private val liveMetalPriceInteractor: LiveMetalPriceInteractor,
    private val liveLondonFixInteractor: LiveLondonFixInteractor,
    private val liveMarketSettingsRepository: LiveMarketSettingsRepository
) : CoroutineViewModel() {

    private val _state = MutableStateFlow(LiveMarketUiState())
    val state: StateFlow<LiveMarketUiState> = _state.asStateFlow()

    fun onRefreshClick(metal: Metal? = null) {
        coroutineScope.launch {
            _state.update {
                when (metal) {
                    Metal.Gold -> it.copy(goldPrices = metal.getPrices())
                    Metal.Silver -> it.copy(silverPrices = metal.getPrices())
                    Metal.Platinum -> it.copy(platinumPrices = metal.getPrices())
                    null -> {
                        val goldPrices = async { Metal.Gold.getPrices() }
                        val silverPrices = async { Metal.Silver.getPrices() }
                        val platinumPrices = async { Metal.Platinum.getPrices() }
                        LiveMarketUiState(
                            goldPrices = goldPrices.await(),
                            silverPrices = silverPrices.await(),
                            platinumPrices = platinumPrices.await()
                        )
                    }
                }
            }
        }
    }

    fun onMeasureUnitChange(metal: Metal, measureUnit: MeasureUnit, forLondonFix: Boolean) {
        if (forLondonFix) liveMarketSettingsRepository.setLondonFixMeasureUnit(metal, measureUnit)
        else liveMarketSettingsRepository.setMarketPriceMeasureUnit(metal, measureUnit)

        getPricesAndUpdateState(metal, forLondonFix)
    }

    fun onCurrencyChange(metal: Metal, currency: Currency, forLondonFix: Boolean) {
        if (forLondonFix) liveMarketSettingsRepository.setLondonFixCurrency(metal, currency)
        else liveMarketSettingsRepository.setMarketPriceCurrency(metal, currency)

        getPricesAndUpdateState(metal, forLondonFix)
    }

    private fun getPricesAndUpdateState(
        metal: Metal,
        forLondonFix: Boolean
    ) {
        coroutineScope.launch {
            _state.update {
                val oldPrices = when (metal) {
                    Metal.Gold -> it.goldPrices
                    Metal.Silver -> it.silverPrices
                    Metal.Platinum -> it.platinumPrices
                }

                val marketPrice = if (!forLondonFix) liveMetalPriceInteractor(metal).toUiState()
                else oldPrices.marketPrice
                val londonFix = if (forLondonFix) liveLondonFixInteractor(metal).toUiState()
                else oldPrices.londonFix

                val newPrices = oldPrices.copy(
                    marketPrice = marketPrice,
                    londonFix = londonFix
                )

                when (metal) {
                    Metal.Gold -> it.copy(goldPrices = newPrices)
                    Metal.Silver -> it.copy(silverPrices = newPrices)
                    Metal.Platinum -> it.copy(platinumPrices = newPrices)
                }
            }
        }
    }

    private suspend fun Metal.getPrices() = coroutineScope {
        val marketPrice = async {
            liveMetalPriceInteractor(this@getPrices).toUiState()
        }
        val londonFix = async {
            liveLondonFixInteractor(this@getPrices).toUiState()
        }
        PricesUiState(
            metal = this@getPrices,
            marketPrice = marketPrice.await(),
            londonFix = londonFix.await()
        )
    }
}
