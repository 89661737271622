package com.kitco.metalynxscrapit.shared.presentation.calculator.viewmodel

import com.kitco.metalynxscrapit.shared.data.repository.PricesRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.ScrapCalculatorSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.interactor.adjusted
import com.kitco.metalynxscrapit.shared.domain.model.AdjustmentType
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyAdjustments
import com.kitco.metalynxscrapit.shared.domain.model.ExchangeRateAdjustment
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.MetalAdjustment
import com.kitco.metalynxscrapit.shared.domain.model.MetalPrice
import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ExchangeRateAdjustmentUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.MetalAdjustmentUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.PriceAdjustmentsUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.toDomain
import com.kitco.metalynxscrapit.shared.presentation.calculator.toUiState
import com.kitco.metalynxscrapit.shared.presentation.common.decimalPartMoreThan
import com.kitco.metalynxscrapit.shared.presentation.common.toString
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class PriceAdjustmentsViewModel(
    private val pricesRepository: PricesRepository,
    private val scrapCalculatorSettingsRepository: ScrapCalculatorSettingsRepository
) : CoroutineViewModel() {

    private val usdAdjustments = scrapCalculatorSettingsRepository.usdAdjustments
    private var selectedCurrency = scrapCalculatorSettingsRepository.currency
    private var currencyAdjustments = scrapCalculatorSettingsRepository.currencyAdjustments
        .first { it.currency == selectedCurrency }
    private var selectedCurrencyRete: Float = 1f
    private lateinit var metalPrices: List<MetalPrice>

    private val _state = MutableStateFlow(createInitialState())
    val state: StateFlow<PriceAdjustmentsUiState> = _state.asStateFlow()

    init {
        coroutineScope.launch {
            metalPrices = pricesRepository.getPreciousMetals()
            selectedCurrencyRete = if (selectedCurrency != Currency.USD) {
                pricesRepository.getCurrencies()
                    .find { it.currency == selectedCurrency }?.priceToUsd ?: 1f
            } else {
                1f
            }

            val usdAdjustments = getUsdAdjustments()
            val exchangeRateAdjustment = getExchangeRateAdjustment()
            val currencyAdjustments = getCurrencyAdjustments(usdAdjustments, exchangeRateAdjustment)

            _state.update {
                it.copy(
                    usdAdjustments = usdAdjustments,
                    exchangeRateAdjustment = exchangeRateAdjustment,
                    currencyAdjustments = currencyAdjustments
                )
            }
        }
    }

    private fun getUsdAdjustments(
        metal: Metal? = null,
        adjustment: String? = null,
        adjustmentType: AdjustmentType? = null
    ): List<MetalAdjustmentUiState> = state.value.usdAdjustments.map {
        if (metal != null && it.metal != metal) return@map it
        val metalPrice = metalPrices.find { price -> price.metal == it.metal }?.bid ?: 0f
        val value = if (adjustment == null) it.adjustment.toFloatOrNull() ?: 0f
        else adjustment.toFloatOrNull() ?: 0f
        val type = adjustmentType ?: it.adjustmentType
        val adjustedPrice = metalPrice.adjusted(value, type)
        it.copy(
            adjustment = adjustment ?: if (value == 0f) "" else value.toString(),
            adjustmentType = type,
            adjustedPrice = adjustedPrice.toString(2)
        )
    }

    private fun getExchangeRateAdjustment(
        adjustment: String? = null,
        adjustmentType: AdjustmentType? = null
    ): ExchangeRateAdjustmentUiState? = state.value.exchangeRateAdjustment?.let {
        val value = if (adjustment == null) it.adjustment.toFloatOrNull() ?: 0f
        else adjustment.toFloatOrNull() ?: 0f
        val type = adjustmentType ?: it.adjustmentType
        it.copy(
            adjustment = adjustment ?: if (value == 0f) "" else value.toString(),
            adjustmentType = type,
            adjustedRate = selectedCurrencyRete.adjusted(value, type).toString(2)
        )
    }

    private fun getCurrencyAdjustments(
        usdAdjustments: List<MetalAdjustmentUiState>,
        exchangeRateAdjustment: ExchangeRateAdjustmentUiState?,
        metal: Metal? = null,
        adjustment: String? = null,
        adjustmentType: AdjustmentType? = null,
    ): List<MetalAdjustmentUiState>? = state.value.currencyAdjustments?.map {
        if (metal != null && it.metal != metal) return@map it
        val metalPrice = usdAdjustments.find { usdAdjustment ->
            usdAdjustment.metal == it.metal
        }?.adjustedPrice?.toFloatOrNull() ?: 0f
        val adjustedRate = exchangeRateAdjustment?.adjustedRate?.toFloatOrNull() ?: 1f
        val value = if (adjustment == null) it.adjustment.toFloatOrNull() ?: 0f
        else adjustment.toFloatOrNull() ?: 0f
        val type = adjustmentType ?: it.adjustmentType
        val adjustedPrice = (metalPrice * adjustedRate).adjusted(value, type)
        it.copy(
            adjustment = adjustment ?: if (value == 0f) "" else value.toString(),
            adjustmentType = type,
            adjustedPrice = adjustedPrice.toString(2)
        )
    }

    fun onDoneClicked() {
        scrapCalculatorSettingsRepository.usdAdjustments =
            state.value.usdAdjustments.map(MetalAdjustmentUiState::toDomain)
        scrapCalculatorSettingsRepository.currencyAdjustments = listOf(
            CurrencyAdjustments(
                currency = selectedCurrency,
                exchangeRateAdjustment = state.value.exchangeRateAdjustment?.toDomain()
                    ?: ExchangeRateAdjustment(selectedCurrency),
                metalAdjustments = state.value.currencyAdjustments?.map(MetalAdjustmentUiState::toDomain)
                    ?: listOf(
                        MetalAdjustment(Metal.Gold),
                        MetalAdjustment(Metal.Silver),
                        MetalAdjustment(Metal.Platinum)
                    )
            )
        )
    }

    fun updateState() {
        selectedCurrency = scrapCalculatorSettingsRepository.currency
        currencyAdjustments = scrapCalculatorSettingsRepository.currencyAdjustments
            .first { it.currency == selectedCurrency }

        coroutineScope.launch {
            selectedCurrencyRete = if (selectedCurrency != Currency.USD) {
                pricesRepository.getCurrencies()
                    .find { it.currency == selectedCurrency }?.priceToUsd ?: 1f
            } else {
                1f
            }

            _state.update { createInitialState() }
            val usdAdjustments = getUsdAdjustments()
            val exchangeRateAdjustment = getExchangeRateAdjustment()
            val currencyAdjustments = getCurrencyAdjustments(usdAdjustments, exchangeRateAdjustment)
            _state.update {
                it.copy(
                    usdAdjustments = usdAdjustments,
                    exchangeRateAdjustment = exchangeRateAdjustment,
                    currencyAdjustments = currencyAdjustments
                )
            }
        }
    }

    fun onResetClicked() {
        _state.update { s ->
            val usdAdjustments = s.usdAdjustments.map {
                val adjustedPrice =
                    metalPrices.find { price -> it.metal == price.metal }?.bid?.toString(2) ?: ""
                it.copy(
                    adjustmentType = AdjustmentType.Points,
                    adjustment = "",
                    adjustedPrice = adjustedPrice
                )
            }
            val exchangeRateAdjustment = s.exchangeRateAdjustment?.copy(
                adjustmentType = AdjustmentType.Points,
                adjustment = "",
                adjustedRate = selectedCurrencyRete.toString(2)
            )
            val currencyAdjustments = s.currencyAdjustments?.map {
                val metalPrice = metalPrices.find { price -> it.metal == price.metal }?.bid ?: 0f
                val adjustedPrice = metalPrice * selectedCurrencyRete
                it.copy(
                    adjustmentType = AdjustmentType.Points,
                    adjustment = "",
                    adjustedPrice = adjustedPrice.toString(2)
                )
            }
            s.copy(
                usdAdjustments = usdAdjustments,
                exchangeRateAdjustment = exchangeRateAdjustment,
                currencyAdjustments = currencyAdjustments,
            )
        }
    }

    fun onUsdAdjustmentChanged(metal: Metal, newAdjustment: String) {
        if (newAdjustment.decimalPartMoreThan(2)) return
        _state.update {
            val usdAdjustments = getUsdAdjustments(metal, newAdjustment)
            val currencyAdjustments =
                getCurrencyAdjustments(usdAdjustments, it.exchangeRateAdjustment)
            it.copy(
                usdAdjustments = usdAdjustments,
                currencyAdjustments = currencyAdjustments
            )
        }
    }

    fun onUsdAdjustmentTypeChanged(metal: Metal, adjustmentType: AdjustmentType) {
        _state.update {
            val usdAdjustments = getUsdAdjustments(metal, adjustmentType = adjustmentType)
            val currencyAdjustments =
                getCurrencyAdjustments(usdAdjustments, it.exchangeRateAdjustment)
            it.copy(
                usdAdjustments = usdAdjustments,
                currencyAdjustments = currencyAdjustments
            )
        }
    }

    fun onCurrencyAdjustmentChanged(metal: Metal, newAdjustment: String) {
        if (newAdjustment.decimalPartMoreThan(2)) return
        _state.update {
            it.copy(
                currencyAdjustments = getCurrencyAdjustments(
                    usdAdjustments = it.usdAdjustments,
                    exchangeRateAdjustment = it.exchangeRateAdjustment,
                    metal = metal,
                    adjustment = newAdjustment
                )
            )
        }
    }

    fun onCurrencyAdjustmentTypeChanged(metal: Metal, adjustmentType: AdjustmentType) {
        _state.update {
            it.copy(
                currencyAdjustments = getCurrencyAdjustments(
                    usdAdjustments = it.usdAdjustments,
                    exchangeRateAdjustment = it.exchangeRateAdjustment,
                    metal = metal,
                    adjustmentType = adjustmentType
                )
            )
        }
    }

    fun onExchangeRateAdjustmentChanged(newAdjustment: String) {
        if (newAdjustment.decimalPartMoreThan(2)) return
        _state.update {
            val exchangeRateAdjustment = getExchangeRateAdjustment(adjustment = newAdjustment)
            val currencyAdjustments =
                getCurrencyAdjustments(it.usdAdjustments, exchangeRateAdjustment)
            it.copy(
                exchangeRateAdjustment = exchangeRateAdjustment,
                currencyAdjustments = currencyAdjustments
            )
        }
    }

    fun onExchangeRateAdjustmentTypeChanged(adjustmentType: AdjustmentType) {
        _state.update {
            val exchangeRateAdjustment = getExchangeRateAdjustment(adjustmentType = adjustmentType)
            val currencyAdjustments =
                getCurrencyAdjustments(it.usdAdjustments, exchangeRateAdjustment)
            it.copy(
                exchangeRateAdjustment = exchangeRateAdjustment,
                currencyAdjustments = currencyAdjustments
            )
        }
    }

    private fun createInitialState(): PriceAdjustmentsUiState {
        val usdAdjustments = usdAdjustments.map(MetalAdjustment::toUiState)
        val (defaultExchangeRateAdjustment, defaultCurrencyAdjustments) =
            if (selectedCurrency != Currency.USD) {
                currencyAdjustments.exchangeRateAdjustment.toUiState() to
                        currencyAdjustments.metalAdjustments.map {
                            it.toUiState().copy(currency = selectedCurrency.name)
                        }
            } else {
                null to null
            }

        return PriceAdjustmentsUiState(
            usdAdjustments = usdAdjustments,
            exchangeRateAdjustment = defaultExchangeRateAdjustment,
            currencyAdjustments = defaultCurrencyAdjustments
        )
    }
}
