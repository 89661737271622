package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.isDecimalNumber
import com.kitco.scrapit.style.AppStylesheet
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun TableDescriptionHeaderView(state: ScrapCalculationTableUiState.Description) {
    Div({
        style {
            width(100.percent)
            height(44.px)
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            alignItems(AlignItems.Center)
            backgroundColor(rgba(232, 232, 232, 1))
        }
    }) {
        Span({
            classes(AppStylesheet.calculatorHeader)
            style {
                width(30.percent)
                paddingLeft(10.px)
            }
        }) {
            Text(stringResource(SharedRes.strings.enter_weight))
        }
        DividerVertical()
        Span({
            classes(AppStylesheet.calculatorHeader)
            style {
                width(20.percent)
            }
        }) {
            Text(stringResource(SharedRes.strings.description))
        }
        DividerVertical()
        Span({
            classes(AppStylesheet.calculatorHeaderLeft)
        }) {
            TextInput(value = state.percent) {
                style {
                    width(50.px)
                    borderRadius(5.px)

                }
                onInput {
                    if (!it.value.isDecimalNumber) return@onInput
                    calculatorViewModel.onPercentChange(state.metal, it.value)
                }
            }
            Span({
                style {
                    marginLeft(5.px)
                }
            }) {
                Text(
                    "% ${
                        stringResource(
                            SharedRes.strings.of_market_value,
                            "${state.marketValue}/oz"
                        )
                    }"
                )
            }
        }
    }
}
