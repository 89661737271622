package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.serialization.Serializable

@Serializable
sealed interface Purity {
    val purity: Float

    enum class Gold(override val purity: Float) : Purity {
        GOLD_9K(0.375f),
        GOLD_10K(0.4167f),
        GOLD_12K(0.5000f),
        GOLD_14K(0.5833f),
        GOLD_16K(0.6667f),
        GOLD_18K(0.7500f),
        GOLD_21K(0.8750f),
        COIN_900(0.9000f),
        COIN_22K(0.9167f),
        GOLD_22K(0.9167f),
        GOLD_24K(0.9999f)
    }

    enum class Silver(override val purity: Float) : Purity {
        SILVER_400(0.4f),
        SILVER_600(0.6f),
        SILVER_800(0.8f),
        SILVER_925(0.925f),
        SILVER_1000(0.9999f)
    }

    enum class Platinum(override val purity: Float) : Purity {
        PLATINUM_90(0.9f),
        PLATINUM_100(0.9999f)
    }
}
