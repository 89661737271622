package com.kitco.metalynxscrapit.shared.data.repository.settings

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.decodeValue
import com.russhwolf.settings.serialization.encodeValue

class LiveMarketSettingsRepository(private val settings: Settings) {

    fun getMarketPriceMeasureUnit(metal: Metal): MeasureUnit = settings.decodeValue(
        MeasureUnit.serializer(),
        metal.marketPriceMeasureUnitKey,
        MeasureUnit.Ounce
    )

    fun setMarketPriceMeasureUnit(metal: Metal, measureUnit: MeasureUnit) = settings.encodeValue(
        MeasureUnit.serializer(),
        metal.marketPriceMeasureUnitKey,
        measureUnit
    )

    fun getMarketPriceCurrency(metal: Metal): Currency = settings.decodeValue(
        Currency.serializer(),
        metal.marketPriceCurrencyKey,
        Currency.USD
    )

    fun setMarketPriceCurrency(metal: Metal, currency: Currency) = settings.encodeValue(
        Currency.serializer(),
        metal.marketPriceCurrencyKey,
        currency
    )

    fun getLondonFixMeasureUnit(metal: Metal): MeasureUnit = settings.decodeValue(
        MeasureUnit.serializer(),
        metal.londonFixMeasureUnitKey,
        MeasureUnit.Ounce
    )

    fun setLondonFixMeasureUnit(metal: Metal, measureUnit: MeasureUnit) = settings.encodeValue(
        MeasureUnit.serializer(),
        metal.londonFixMeasureUnitKey,
        measureUnit
    )

    fun getLondonFixCurrency(metal: Metal): Currency = settings.decodeValue(
        Currency.serializer(),
        metal.londonFixCurrencyKey,
        Currency.USD
    )

    fun setLondonFixCurrency(metal: Metal, currency: Currency) = settings.encodeValue(
        Currency.serializer(),
        metal.londonFixCurrencyKey,
        currency
    )
}
