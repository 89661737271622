package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.PurityItemUiState
import com.kitco.metalynxscrapit.shared.presentation.common.title
import com.kitco.scrapit.components.CalculatorItemViewEdit
import com.kitco.scrapit.components.TableDescriptionHeaderEdit
import com.kitco.scrapit.components.TableHeaderViewEdit
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun TableViewEdit(
    metal: Metal,
    purities: List<PurityItemUiState>,
    editMode: MutableState<Boolean>
) {
    Div({
        style {
            width(100.percent)
            border(1.px, LineStyle.Solid, rgba(180, 180, 180, 1))
            borderWidth(1.px)
            borderRadius(7.px)
            marginTop(10.px)
            fontFamily("Arial")
        }
    }) {
        TableHeaderViewEdit(stringResource(metal.title), editMode)
        TableDescriptionHeaderEdit(metal)
        purities.forEach {
            CalculatorItemViewEdit(it)
        }
    }
}
