package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.scrapit.handlers.handleWidgetMove
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import com.kitco.scrapit.view.DividerVertical
import com.kitco.scrapit.view.WidgetStatusType
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun MobileMenuBar(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    Div(
        attrs = {
            classes(ComponentsStylesheet.mobileMenuWrap)
        }
    ) {
        Div(
            attrs = {
                classes(ComponentsStylesheet.menuItem, CommonStyle.flexCentered)
                onClick {
                    handleWidgetMove(
                        widgetStatus,
                        WidgetStatusType.MODIFY,
                        transitionStatus
                    )
                }

            }
        ) {
            Text(stringResource(SharedRes.strings.modify))
        }
//        DividerVertical()
//        Div (
//            attrs = {
//                classes(ComponentsStylesheet.menuItem, CommonStyle.flexCentered)
//                onClick {
//                    handleWidgetMove(
//                        widgetStatus,
//                        WidgetStatusType.LIVE_MARKET,
//                        transitionStatus
//                    )
//                }
//            }
//        ) {
//            Text(stringResource(SharedRes.strings.live_market))
//        }
//        DividerVertical()
//        Div(
//            attrs = {
//                classes(ComponentsStylesheet.menuItem, CommonStyle.flexCentered)
//                onClick {
//                    handleWidgetMove(
//                        widgetStatus,
//                        WidgetStatusType.CONTACT_US,
//                        transitionStatus
//                    )
//                }
//            }
//        ) {
//            Text(stringResource(SharedRes.strings.contact))
//        }
//        DividerVertical()
//        Div(
//            attrs = {
//                classes(ComponentsStylesheet.menuItem, CommonStyle.flexCentered)
//                onClick {
//                    handleWidgetMove(
//                        widgetStatus,
//                        WidgetStatusType.MORE,
//                        transitionStatus
//                    )
//                }
//            }
//        ) {
//            Text(stringResource(SharedRes.strings.more))
//        }
//        DividerVertical()
    }
}