package com.kitco.metalynxscrapit.shared.presentation.calculator.state

import com.kitco.metalynxscrapit.shared.domain.model.AdjustmentType
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import dev.icerock.moko.resources.StringResource

data class PriceAdjustmentsUiState(
    val usdAdjustments: List<MetalAdjustmentUiState>,
    val exchangeRateAdjustment: ExchangeRateAdjustmentUiState? = null,
    val currencyAdjustments: List<MetalAdjustmentUiState>? = null
)

data class MetalAdjustmentUiState(
    val metal: Metal,
    val title: StringResource,
    val adjustmentType: AdjustmentType,
    val currency: String,
    val adjustment: String,
    val adjustedPrice: String
)

data class ExchangeRateAdjustmentUiState(
    val currency: Currency,
    val title: String,
    val adjustmentType: AdjustmentType,
    val adjustment: String,
    val adjustedRate: String
)
