package com.kitco.metalynxscrapit.shared

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocale
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object SharedRes {
  private val contentHash: String = "b727094aebe09b8f9841bce724e4412c"

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    private val fallbackFileUrl: String =
        js("require(\"./localization/comkitcometalynxscrapitshared_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("fr",
            js("require(\"./localization/comkitcometalynxscrapitshared_stringsJson_fr.json\")") as
            String),
        ))

    public override val stringsLoader: RemoteJsStringLoader =
        RemoteJsStringLoader.Impl(supportedLocales = supportedLocales, fallbackFileUri =
        fallbackFileUrl)

    public actual val all: StringResource = StringResource(key = "all", loader = stringsLoader)

    public actual val live_market: StringResource = StringResource(key = "live_market", loader =
        stringsLoader)

    public actual val contact: StringResource = StringResource(key = "contact", loader =
        stringsLoader)

    public actual val feedback: StringResource = StringResource(key = "feedback", loader =
        stringsLoader)

    public actual val more: StringResource = StringResource(key = "more", loader = stringsLoader)

    public actual val price_adjustments: StringResource = StringResource(key = "price_adjustments",
        loader = stringsLoader)

    public actual val cancel: StringResource = StringResource(key = "cancel", loader =
        stringsLoader)

    public actual val done: StringResource = StringResource(key = "done", loader = stringsLoader)

    public actual val edit: StringResource = StringResource(key = "edit", loader = stringsLoader)

    public actual val description: StringResource = StringResource(key = "description", loader =
        stringsLoader)

    public actual val display_hide: StringResource = StringResource(key = "display_hide", loader =
        stringsLoader)

    public actual val adjustment_exchange_rate_description: StringResource = StringResource(key =
        "adjustment_exchange_rate_description", loader = stringsLoader)

    public actual val current_metal_price: StringResource = StringResource(key =
        "current_metal_price", loader = stringsLoader)

    public actual val london_fix_prices: StringResource = StringResource(key = "london_fix_prices",
        loader = stringsLoader)

    public actual val reset: StringResource = StringResource(key = "reset", loader = stringsLoader)

    public actual val metal: StringResource = StringResource(key = "metal", loader = stringsLoader)

    public actual val exch_rate: StringResource = StringResource(key = "exch_rate", loader =
        stringsLoader)

    public actual val adjustment_type_percent: StringResource = StringResource(key =
        "adjustment_type_percent", loader = stringsLoader)

    public actual val current_market_price: StringResource = StringResource(key =
        "current_market_price", loader = stringsLoader)

    public actual val bid_ask: StringResource = StringResource(key = "bid_ask", loader =
        stringsLoader)

    public actual val high_low: StringResource = StringResource(key = "high_low", loader =
        stringsLoader)

    public actual val scrap_calculator: StringResource = StringResource(key = "scrap_calculator",
        loader = stringsLoader)

    public actual val refresh_prices: StringResource = StringResource(key = "refresh_prices", loader
        = stringsLoader)

    public actual val enter_weight: StringResource = StringResource(key = "enter_weight", loader =
        stringsLoader)

    public actual val of_market_value: StringResource = StringResource(key = "of_market_value",
        loader = stringsLoader)

    public actual val note: StringResource = StringResource(key = "note", loader = stringsLoader)

    public actual val please_call_us: StringResource = StringResource(key = "please_call_us", loader
        = stringsLoader)

    public actual val feedback_message: StringResource = StringResource(key = "feedback_message",
        loader = stringsLoader)

    public actual val indicates_required_fields: StringResource = StringResource(key =
        "indicates_required_fields", loader = stringsLoader)

    public actual val your_email_address: StringResource = StringResource(key =
        "your_email_address", loader = stringsLoader)

    public actual val enter_your_email_address: StringResource = StringResource(key =
        "enter_your_email_address", loader = stringsLoader)

    public actual val category: StringResource = StringResource(key = "category", loader =
        stringsLoader)

    public actual val feedback_category_questions: StringResource = StringResource(key =
        "feedback_category_questions", loader = stringsLoader)

    public actual val feedback_category_problems_app: StringResource = StringResource(key =
        "feedback_category_problems_app", loader = stringsLoader)

    public actual val feedback_category_problems_data: StringResource = StringResource(key =
        "feedback_category_problems_data", loader = stringsLoader)

    public actual val suggestions: StringResource = StringResource(key = "suggestions", loader =
        stringsLoader)

    public actual val feedback_category_other: StringResource = StringResource(key =
        "feedback_category_other", loader = stringsLoader)

    public actual val message: StringResource = StringResource(key = "message", loader =
        stringsLoader)

    public actual val send: StringResource = StringResource(key = "send", loader = stringsLoader)

    public actual val settings: StringResource = StringResource(key = "settings", loader =
        stringsLoader)

    public actual val language: StringResource = StringResource(key = "language", loader =
        stringsLoader)

    public actual val time_zone: StringResource = StringResource(key = "time_zone", loader =
        stringsLoader)

    public actual val kitco_products_and_services: StringResource = StringResource(key =
        "kitco_products_and_services", loader = stringsLoader)

    public actual val metalynx: StringResource = StringResource(key = "metalynx", loader =
        stringsLoader)

    public actual val online_store: StringResource = StringResource(key = "online_store", loader =
        stringsLoader)

    public actual val kitco_com: StringResource = StringResource(key = "kitco_com", loader =
        stringsLoader)

    public actual val gold_live: StringResource = StringResource(key = "gold_live", loader =
        stringsLoader)

    public actual val more_apps: StringResource = StringResource(key = "more_apps", loader =
        stringsLoader)

    public actual val services: StringResource = StringResource(key = "services", loader =
        stringsLoader)

    public actual val terms_and_conditions: StringResource = StringResource(key =
        "terms_and_conditions", loader = stringsLoader)

    public actual val faqs: StringResource = StringResource(key = "faqs", loader = stringsLoader)

    public actual val local: StringResource = StringResource(key = "local", loader = stringsLoader)

    public actual val ny_time: StringResource = StringResource(key = "ny_time", loader =
        stringsLoader)

    public actual val gmt: StringResource = StringResource(key = "gmt", loader = stringsLoader)

    public actual val kitco_android_app: StringResource = StringResource(key = "kitco_android_app",
        loader = stringsLoader)

    public actual val kitco_ios_app: StringResource = StringResource(key = "kitco_ios_app", loader =
        stringsLoader)

    public actual val adjustment_description: StringResource = StringResource(key =
        "adjustment_description", loader = stringsLoader)

    public actual val your_adjustments: StringResource = StringResource(key = "your_adjustments",
        loader = stringsLoader)

    public actual val adjusted_exch_rate: StringResource = StringResource(key =
        "adjusted_exch_rate", loader = stringsLoader)

    public actual val adjusted_price_usd: StringResource = StringResource(key =
        "adjusted_price_usd", loader = stringsLoader)

    public actual val canada_headquarters: StringResource = StringResource(key =
        "canada_headquarters", loader = stringsLoader)

    public actual val china_hksar: StringResource = StringResource(key = "china_hksar", loader =
        stringsLoader)

    public actual val precious_metals_and_refining: StringResource = StringResource(key =
        "precious_metals_and_refining", loader = stringsLoader)

    public actual val customer_service: StringResource = StringResource(key = "customer_service",
        loader = stringsLoader)

    public actual val hours_of_operation: StringResource = StringResource(key =
        "hours_of_operation", loader = stringsLoader)

    public actual val monday_to_friday: StringResource = StringResource(key = "monday_to_friday",
        loader = stringsLoader)

    public actual val customer_service_counter: StringResource = StringResource(key =
        "customer_service_counter", loader = stringsLoader)

    public actual val open_hours_a_day_days: StringResource = StringResource(key =
        "open_hours_a_day_days", loader = stringsLoader)

    public actual val mobile_online_store: StringResource = StringResource(key =
        "mobile_online_store", loader = stringsLoader)

    public actual val media: StringResource = StringResource(key = "media", loader = stringsLoader)

    public actual val kitco_news: StringResource = StringResource(key = "kitco_news", loader =
        stringsLoader)

    public actual val advertise_with_kitco: StringResource = StringResource(key =
        "advertise_with_kitco", loader = stringsLoader)

    public actual val global_offices: StringResource = StringResource(key = "global_offices", loader
        = stringsLoader)

    public actual val montreal: StringResource = StringResource(key = "montreal", loader =
        stringsLoader)

    public actual val new_york: StringResource = StringResource(key = "new_york", loader =
        stringsLoader)

    public actual val hong_kong: StringResource = StringResource(key = "hong_kong", loader =
        stringsLoader)

    public actual val contact_us: StringResource = StringResource(key = "contact_us", loader =
        stringsLoader)

    public actual val exchange_rate_adjustment_info: StringResource = StringResource(key =
        "exchange_rate_adjustment_info", loader = stringsLoader)

    public actual val currency_adjustment_info: StringResource = StringResource(key =
        "currency_adjustment_info", loader = stringsLoader)

    public actual val version: StringResource = StringResource(key = "version", loader =
        stringsLoader)

    public actual val gold: StringResource = StringResource(key = "gold", loader = stringsLoader)

    public actual val silver: StringResource = StringResource(key = "silver", loader =
        stringsLoader)

    public actual val platinum: StringResource = StringResource(key = "platinum", loader =
        stringsLoader)

    public actual val ounce_base: StringResource = StringResource(key = "ounce_base", loader =
        stringsLoader)

    public actual val gram_base: StringResource = StringResource(key = "gram_base", loader =
        stringsLoader)

    public actual val kilo_base: StringResource = StringResource(key = "kilo_base", loader =
        stringsLoader)

    public actual val dwt_base: StringResource = StringResource(key = "dwt_base", loader =
        stringsLoader)

    public actual val tola_base: StringResource = StringResource(key = "tola_base", loader =
        stringsLoader)

    public actual val tael_base: StringResource = StringResource(key = "tael_base", loader =
        stringsLoader)

    public actual val ounce_short: StringResource = StringResource(key = "ounce_short", loader =
        stringsLoader)

    public actual val gram_short: StringResource = StringResource(key = "gram_short", loader =
        stringsLoader)

    public actual val kilo_short: StringResource = StringResource(key = "kilo_short", loader =
        stringsLoader)

    public actual val gold_purity_9K: StringResource = StringResource(key = "gold_purity_9K", loader
        = stringsLoader)

    public actual val gold_purity_10K: StringResource = StringResource(key = "gold_purity_10K",
        loader = stringsLoader)

    public actual val gold_purity_12K: StringResource = StringResource(key = "gold_purity_12K",
        loader = stringsLoader)

    public actual val gold_purity_14K: StringResource = StringResource(key = "gold_purity_14K",
        loader = stringsLoader)

    public actual val gold_purity_16K: StringResource = StringResource(key = "gold_purity_16K",
        loader = stringsLoader)

    public actual val gold_purity_18K: StringResource = StringResource(key = "gold_purity_18K",
        loader = stringsLoader)

    public actual val gold_purity_21K: StringResource = StringResource(key = "gold_purity_21K",
        loader = stringsLoader)

    public actual val gold_purity_900: StringResource = StringResource(key = "gold_purity_900",
        loader = stringsLoader)

    public actual val gold_purity_coin_22K: StringResource = StringResource(key =
        "gold_purity_coin_22K", loader = stringsLoader)

    public actual val gold_purity_22K: StringResource = StringResource(key = "gold_purity_22K",
        loader = stringsLoader)

    public actual val gold_purity_24K: StringResource = StringResource(key = "gold_purity_24K",
        loader = stringsLoader)

    public actual val silver_purity_pure: StringResource = StringResource(key =
        "silver_purity_pure", loader = stringsLoader)

    public actual val silver_purity_sterling: StringResource = StringResource(key =
        "silver_purity_sterling", loader = stringsLoader)

    public actual val silver_purity_800: StringResource = StringResource(key = "silver_purity_800",
        loader = stringsLoader)

    public actual val silver_purity_600: StringResource = StringResource(key = "silver_purity_600",
        loader = stringsLoader)

    public actual val silver_purity_400: StringResource = StringResource(key = "silver_purity_400",
        loader = stringsLoader)

    public actual val platinum_purity_pure: StringResource = StringResource(key =
        "platinum_purity_pure", loader = stringsLoader)

    public actual val platinum_purity_90: StringResource = StringResource(key =
        "platinum_purity_90", loader = stringsLoader)

    public actual val scrapit: StringResource = StringResource(key = "scrapit", loader =
        stringsLoader)

    public actual val modify: StringResource = StringResource(key = "modify", loader =
        stringsLoader)

    public actual val note_title: StringResource = StringResource(key = "note_title", loader =
        stringsLoader)

    public actual override fun values(): List<StringResource> = listOf(all, live_market, contact,
        feedback, more, price_adjustments, cancel, done, edit, description, display_hide,
        adjustment_exchange_rate_description, current_metal_price, london_fix_prices, reset, metal,
        exch_rate, adjustment_type_percent, current_market_price, bid_ask, high_low,
        scrap_calculator, refresh_prices, enter_weight, of_market_value, note, please_call_us,
        feedback_message, indicates_required_fields, your_email_address, enter_your_email_address,
        category, feedback_category_questions, feedback_category_problems_app,
        feedback_category_problems_data, suggestions, feedback_category_other, message, send,
        settings, language, time_zone, kitco_products_and_services, metalynx, online_store,
        kitco_com, gold_live, more_apps, services, terms_and_conditions, faqs, local, ny_time, gmt,
        kitco_android_app, kitco_ios_app, adjustment_description, your_adjustments,
        adjusted_exch_rate, adjusted_price_usd, canada_headquarters, china_hksar,
        precious_metals_and_refining, customer_service, hours_of_operation, monday_to_friday,
        customer_service_counter, open_hours_a_day_days, mobile_online_store, media, kitco_news,
        advertise_with_kitco, global_offices, montreal, new_york, hong_kong, contact_us,
        exchange_rate_adjustment_info, currency_adjustment_info, version, gold, silver, platinum,
        ounce_base, gram_base, kilo_base, dwt_base, tola_base, tael_base, ounce_short, gram_short,
        kilo_short, gold_purity_9K, gold_purity_10K, gold_purity_12K, gold_purity_14K,
        gold_purity_16K, gold_purity_18K, gold_purity_21K, gold_purity_900, gold_purity_coin_22K,
        gold_purity_22K, gold_purity_24K, silver_purity_pure, silver_purity_sterling,
        silver_purity_800, silver_purity_600, silver_purity_400, platinum_purity_pure,
        platinum_purity_90, scrapit, modify, note_title)
  }

  public actual object images : ResourceContainer<ImageResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val img_services: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_services.png\")") as String, fileName = "img_services.png")

    public actual val au: ImageResource = ImageResource(fileUrl = js("require(\"./images/au.png\")")
        as String, fileName = "au.png")

    public actual val hk: ImageResource = ImageResource(fileUrl = js("require(\"./images/hk.png\")")
        as String, fileName = "hk.png")

    public actual val ic_refresh: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_refresh.svg\")") as String, fileName = "ic_refresh.svg")

    public actual val ic_scrapit: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_scrapit.png\")") as String, fileName = "ic_scrapit.png")

    public actual val mx: ImageResource = ImageResource(fileUrl = js("require(\"./images/mx.png\")")
        as String, fileName = "mx.png")

    public actual val img_mobile: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_mobile.png\")") as String, fileName = "img_mobile.png")

    public actual val br: ImageResource = ImageResource(fileUrl = js("require(\"./images/br.png\")")
        as String, fileName = "br.png")

    public actual val ic_add: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_add.svg\")") as String, fileName = "ic_add.svg")

    public actual val ic_live_market: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_live_market.png\")") as String, fileName = "ic_live_market.png")

    public actual val img_gold_live: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_gold_live.png\")") as String, fileName = "img_gold_live.png")

    public actual val ch: ImageResource = ImageResource(fileUrl = js("require(\"./images/ch.png\")")
        as String, fileName = "ch.png")

    public actual val jp: ImageResource = ImageResource(fileUrl = js("require(\"./images/jp.png\")")
        as String, fileName = "jp.png")

    public actual val img_metalynx: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_metalynx.png\")") as String, fileName = "img_metalynx.png")

    public actual val img_more_apps: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_more_apps.png\")") as String, fileName = "img_more_apps.png")

    public actual val za: ImageResource = ImageResource(fileUrl = js("require(\"./images/za.png\")")
        as String, fileName = "za.png")

    public actual val uk: ImageResource = ImageResource(fileUrl = js("require(\"./images/uk.png\")")
        as String, fileName = "uk.png")

    public actual val eu: ImageResource = ImageResource(fileUrl = js("require(\"./images/eu.png\")")
        as String, fileName = "eu.png")

    public actual val ic_contact: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_contact.png\")") as String, fileName = "ic_contact.png")

    public actual val ru: ImageResource = ImageResource(fileUrl = js("require(\"./images/ru.png\")")
        as String, fileName = "ru.png")

    public actual val us: ImageResource = ImageResource(fileUrl = js("require(\"./images/us.png\")")
        as String, fileName = "us.png")

    public actual val inr: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/inr.png\")") as String, fileName = "inr.png")

    public actual val img_store: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_store.png\")") as String, fileName = "img_store.png")

    public actual val ic_more: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ic_more.png\")") as String, fileName = "ic_more.png")

    public actual val ca: ImageResource = ImageResource(fileUrl = js("require(\"./images/ca.png\")")
        as String, fileName = "ca.png")

    public actual val cn: ImageResource = ImageResource(fileUrl = js("require(\"./images/cn.png\")")
        as String, fileName = "cn.png")

    public actual val img_terms: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/img_terms.png\")") as String, fileName = "img_terms.png")

    public actual override fun values(): List<ImageResource> = listOf(img_services, au, hk,
        ic_refresh, ic_scrapit, mx, img_mobile, br, ic_add, ic_live_market, img_gold_live, ch, jp,
        img_metalynx, img_more_apps, za, uk, eu, ic_contact, ru, us, inr, img_store, ic_more, ca,
        cn, img_terms)
  }

  public actual object colors : ResourceContainer<ColorResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val blue: ColorResource = ColorResource(lightColor = Color(0x00a3ffff), darkColor
        = Color(0x00a3ffff))

    public actual override fun values(): List<ColorResource> = listOf(blue)
  }
}
