package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.scrapit.components.widgets.LiveMarket
import com.kitco.scrapit.components.widgets.PriceAdjustments
import com.kitco.scrapit.style.AppStylesheet
import org.jetbrains.compose.web.dom.Div

@Composable
fun WidgetContainerViewMobile(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    Div(
        attrs = {
            classes(
                AppStylesheet.widgetsSectionMobile,
                if (widgetStatus.value == WidgetStatusType.NONE || transitionStatus.value) AppStylesheet.hideMobile else AppStylesheet.showMobile
            )
        }
    ) {
        when {
            widgetStatus.value == WidgetStatusType.MODIFY || transitionStatus.value && widgetStatus.value == WidgetStatusType.MODIFY -> {
                PriceAdjustments(widgetStatus, transitionStatus)
            }

            widgetStatus.value == WidgetStatusType.LIVE_MARKET || transitionStatus.value && widgetStatus.value == WidgetStatusType.LIVE_MARKET -> {
                LiveMarket(widgetStatus, transitionStatus)
            }

            // Commenting out the "Contact Us" section
            /*
            widgetStatus.value == WidgetStatusType.CONTACT_US || transitionStatus.value && widgetStatus.value == WidgetStatusType.CONTACT_US -> {
                ContactUs(widgetStatus, transitionStatus)
            }
            */

            // Commenting out the "More" section
            /*
            widgetStatus.value == WidgetStatusType.MORE || transitionStatus.value && widgetStatus.value == WidgetStatusType.MORE -> {
                More(widgetStatus, transitionStatus)
            }
            */
        }
    }
}
