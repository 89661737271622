package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.serialization.Serializable

@Serializable
enum class Language(val code: String) {
    English("en"), French("fr");

    companion object {
        fun fromCode(languageCode: String): Language {
            return Language.entries.firstOrNull {
                it.code == languageCode
            } ?: error("Not supported language code: $languageCode")
        }
    }
}
