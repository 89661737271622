package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.scrapit.style.AppStylesheet
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun MainView(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>,
    editMode: MutableState<Boolean>
) {
    Div(
        attrs = { classes(AppStylesheet.mainContainerInner) }
    ) {
        Div(
            attrs = { classes(AppStylesheet.wrapperCalculator) }
        ) {
            Div(
                attrs = { classes(AppStylesheet.calculatorSection) }
            ) {
                CalculatorView(editMode)
            }

            Div(
                attrs = { classes(AppStylesheet.widgetsSection) }
            ) {
                WidgetsContainerView(widgetStatus, transitionStatus)
            }
        }
    }
    WidgetContainerViewMobile(widgetStatus, transitionStatus)
}
