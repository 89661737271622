package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.datetime.TimeZone
import kotlinx.datetime.UtcOffset
import kotlinx.datetime.asTimeZone
import kotlinx.serialization.Serializable

@Serializable
enum class AppTimeZone(val zoneId: String) {
    America("America/New_York"),
    GMT("GMT"),
    Local("Local");


    fun toKotlinTimeZone() = when (this) {
        America -> UtcOffset(-4).asTimeZone()
        GMT -> TimeZone.UTC
        Local -> TimeZone.currentSystemDefault()
    }
}
