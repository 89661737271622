package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.Language
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.presentation.common.DropDownItemState
import com.kitco.metalynxscrapit.shared.presentation.common.DropdownState
import com.kitco.metalynxscrapit.shared.presentation.common.icon
import com.kitco.metalynxscrapit.shared.presentation.common.title
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.components.ButtonBase
import com.kitco.scrapit.components.DropDown
import com.kitco.scrapit.components.TypeIcon
import com.kitco.scrapit.handlers.handleWidgetMove
import com.kitco.scrapit.moreViewModel
import com.kitco.scrapit.priceAdjustmentsViewModel
import com.kitco.scrapit.style.AppStylesheet
import com.kitco.scrapit.style.CommonStyle
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun MenuBarView(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    val state by calculatorViewModel.state.collectAsState()

    Div(
        attrs = { classes(AppStylesheet.menuContainer) }
    ) {
        Div(
            attrs = { classes(AppStylesheet.menuBarWrapper) }
        ) {
            Div(
                attrs = {
                    classes(AppStylesheet.titleApp)
                }
            ) {
                Text(stringResource(SharedRes.strings.scrap_calculator))
            }
            Div({
                style {
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Center)
                }
            }) {

                Div(
                    attrs = {
                        classes(CommonStyle.modify_wrapper_mobile)
                    }
                ) {
                    DropDown(
                        DropdownState(
                            selectedItem = state.currency.title,
                            items = Currency.entries.map {
                                DropDownItemState(
                                    it.ordinal,
                                    it.name,
                                    it.icon
                                )
                            },
                            leadingIcon = state.currency.icon
                        ),
                        onItemClick = {
                            calculatorViewModel.onCurrencySelect(Currency.entries[it.id])
                            priceAdjustmentsViewModel.updateState()
                        },
                        titleSize = 14
                    )
                    DropDown(
                        DropdownState(
                            selectedItem = stringResource(state.measureUnit.title),
                            items = MeasureUnit.entries.map {
                                DropDownItemState(
                                    it.ordinal,
                                    it.name
                                )
                            }
                        ),
                        onItemClick = { calculatorViewModel.onMeasureUnitSelect(MeasureUnit.entries[it.id]) },
                        titleSize = 16
                    )
                }

                Div(
                    attrs = {
                        classes(CommonStyle.modify_wrapper_desktop)
                    }
                ) {
                    ButtonBase(
                        stringResource(SharedRes.strings.modify),
                        17,
                        TypeIcon.NONE
                    ) {
                        handleWidgetMove(widgetStatus, WidgetStatusType.MODIFY, transitionStatus)
                    }
                }

                Div(
                    attrs = { classes(CommonStyle.ml_10, CommonStyle.lang_btn_mobile) }
                ) {
                    val moreState by moreViewModel.state.collectAsState()
                    val language = if (moreState.language == Language.English) Language.French
                    else Language.English
                    ButtonBase(language.title, 17, TypeIcon.NONE) {
                        moreViewModel.onLanguageChange(language)
                    }
                }
            }
        }
    }
}
