package com.kitco.metalynxscrapit.shared.presentation.feedback

import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update

class FeedbackViewModel : CoroutineViewModel() {

    private val _state = MutableStateFlow(FeedbackUiState())
    val state: StateFlow<FeedbackUiState> = _state.asStateFlow()

    fun onEmailChange(email: String) {
        _state.update { it.copy(email = email) }
    }

    fun onCategoryChange(category: Category) {
        _state.update { it.copy(category = category) }
    }

    fun onFeedbackChange(message: String) {
        _state.update { it.copy(message = message) }
    }
}
