package com.kitco.metalynxscrapit.shared.data.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CurrenciesDto(@SerialName("Rates") private val rates: RatesDto) {
    val list get() = rates.rates
}

@Serializable
data class RatesDto(
    @SerialName("Rate") val rates: List<CurrencyDto>
)

@Serializable
data class CurrencyDto(
    @SerialName("CtoUSD") val ctoUSD: Float,
    @SerialName("Symbol") val symbol: String,
    @SerialName("Ask") val ask: Float,
    @SerialName("ChangePercent") val changePercent: Float,
    @SerialName("Change") val change: Float,
    @SerialName("Bid") val bid: Float,
    @SerialName("USDtoC") val usdToC: Float,
    @SerialName("Timestamp") val timestamp: String
)
