package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.scrapit.components.widgets.PriceAdjustments
import com.kitco.scrapit.style.WidgetStyleSheet
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.href
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div

private const val bannerLink =
    "https://online.kitco.com/sell/gold-silver.html?utm_source=ScrapIt&utm_medium=referral&utm_campaign=ScrapIt-Banner&utm_content=sell-coins-and-bars"

@Composable
fun WidgetsContainerView(
    widgetStatus: MutableState<WidgetStatusType>,
    transitionStatus: MutableState<Boolean>
) {
    val showModify =
        widgetStatus.value == WidgetStatusType.MODIFY
                || (transitionStatus.value && widgetStatus.value == WidgetStatusType.MODIFY)
    A(
        attrs = {
            if (!showModify) {
                href(bannerLink)
                target(ATarget.Blank) // Ensures the link opens in a new tab
            }
        }
    ) {
        Div(
            attrs = { classes(WidgetStyleSheet.widgetWrapperContainer) }
        ) {
            Div(
                attrs = {
                    classes(
                        WidgetStyleSheet.asideContainer,
                        if (widgetStatus.value == WidgetStatusType.NONE || transitionStatus.value)
                            WidgetStyleSheet.hideAside else WidgetStyleSheet.showAside
                    )
                }
            ) {
                if (showModify) PriceAdjustments(widgetStatus, transitionStatus)
            }
        }
    }
}
