package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class MetalPrice(
    val metal: Metal,
    val measureUnit: MeasureUnit,
    val currency: Currency,
    val timestamp: Instant,
    val mid: Float,
    val bid: Float,
    val ask: Float,
    val high: Float,
    val low: Float,
    val change: Float,
    val changePercentage: Float
)
