package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.isDecimalNumber
import com.kitco.scrapit.style.CommonStyle
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun DividerVertical() {
    Div({
        style {
            height(100.percent)
            width(1.px)
            backgroundColor(rgba(180, 180, 180, 1))
        }
    })
}

@Composable
fun CalculatorItemView(state: ScrapCalculationTableUiState.PurityItem) {
    Div({
        style {
            width(100.percent)
            height(1.px)
            backgroundColor(rgba(180, 180, 180, 1))
        }
    }) { }

    Div({
        style {
            width(100.percent)
            height(48.px)
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            alignItems(AlignItems.Center)
            fontSize(16.px)
        }
    }) {
        Span({
            classes(CommonStyle.calcInputWrap, CommonStyle.calcItemFontSz)
        }) {
            TextInput(
                value = state.weight,
                attrs = {
                    classes(CommonStyle.textInputStyle, CommonStyle.calcItemInput)
                    style {
                        height(20.px)
                        borderRadius(5.px)
                        marginRight(5.px)
                        border(1.px, LineStyle.Solid, rgba(180, 180, 180, 1))
                    }
                    onInput {
                        if (!it.value.isDecimalNumber) return@onInput
                        calculatorViewModel.onWeightChange(
                            state.metal,
                            state.purity,
                            it.value
                        )
                    }
                }
            )
            Span({
                classes(CommonStyle.calcInputLabel)
            }) {
                Text(stringResource(state.measureUnit))
            }
        }
        DividerVertical()
        Span({
            classes(CommonStyle.calcItemFontSz)
            style {
                width(20.percent)
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                justifyContent(JustifyContent.Center)
            }
        }) {
            val text = if (state.purity == Purity.Platinum.PLATINUM_90) {
                stringResource(
                    state.purityTitle,
                    "% "
                )
            } else {
                stringResource(state.purityTitle)
            }
            Text(text)
        }
        DividerVertical()
        Span({
            classes(CommonStyle.calcItemFontSz)
            style {
                width(50.percent)
                height(100.percent)
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.Center)
                backgroundColor(rgba(249, 249, 249, 1))
            }
        }) {
            Text(state.result)
        }
    }
}
