package com.kitco.metalynxscrapit.shared.domain.interactor

import com.kitco.metalynxscrapit.shared.data.repository.PricesRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.AppSettingsRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.ScrapCalculatorSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyRate
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.MetalPrice
import com.kitco.metalynxscrapit.shared.domain.model.PurityResult
import com.kitco.metalynxscrapit.shared.domain.model.ScrapCalculationResult
import kotlinx.datetime.Instant

class ScrapCalculatorInteractor(
    private val pricesRepository: PricesRepository,
    private val settingsRepository: ScrapCalculatorSettingsRepository,
    private val appSettingsRepository: AppSettingsRepository
) {

    private var usdAdjustments = settingsRepository.usdAdjustments
    private var allCurrencyAdjustments = settingsRepository.currencyAdjustments
    private var showWeightEntryColumn = settingsRepository.showWeightEntryColumn
    private var metalPrices: List<MetalPrice>? = null
    private var currencyRates: List<CurrencyRate>? = null

    suspend operator fun invoke(
        metal: Metal,
        refreshSettings: Boolean = false,
        refreshPrices: Boolean = false
    ): ScrapCalculationResult {
        if (refreshSettings) {
            usdAdjustments = settingsRepository.usdAdjustments
            allCurrencyAdjustments = settingsRepository.currencyAdjustments
            showWeightEntryColumn = settingsRepository.showWeightEntryColumn
        }
        if (refreshPrices) {
            metalPrices = null
            currencyRates = null
        }

        val currency = settingsRepository.currency
        val usdAdjustment = usdAdjustments.first { it.metal == metal }
        val currencyAdjustments = allCurrencyAdjustments.first { it.currency == currency }
        val currencyAdjustment = currencyAdjustments.metalAdjustments.first { it.metal == metal }
        val exchangeRateAdjustment = currencyAdjustments.exchangeRateAdjustment

        val measureUnit = settingsRepository.measureUnit
        val percent = settingsRepository.getCalculationPercent(metal)

        val customPrice = settingsRepository.getCustomPrice(metal)
        var metalPriceTimestamp: Instant? = null
        val price = customPrice ?: (metalPrices ?: pricesRepository.getPreciousMetals(refreshPrices)
            .also { metalPrices = it })
            .first { it.metal == metal }
            .also { metalPriceTimestamp = it.timestamp }
            .bid
        val priceAdjusted = customPrice ?: price.adjusted(usdAdjustment.value, usdAdjustment.type)

        val customExchangeRate = settingsRepository.getCustomRate()
        var currencyRateTimestamp: Instant? = null
        val exchangeRate =
            customExchangeRate ?: (currencyRates ?: pricesRepository.getCurrencies(refreshPrices)
                .also { currencyRates = it })
                .first { it.currency == currency }
                .also { currencyRateTimestamp = it.timestamp }
                .priceToUsd
        val exchangeRateAdjusted = customExchangeRate ?: exchangeRate.adjusted(
            exchangeRateAdjustment.value,
            exchangeRateAdjustment.type
        )

        val priceInCurrency = price * exchangeRate
        val priceInCurrencyAdjusted = (priceAdjusted * exchangeRateAdjusted).adjusted(
            currencyAdjustment.value,
            currencyAdjustment.type
        )

        val purityResults = settingsRepository.puritySettings.mapNotNull {
            if (!it.enabled || it.metal != metal) return@mapNotNull null
            val weight = if (showWeightEntryColumn) it.weight else 1f
            val purityPrice =
                priceInCurrencyAdjusted * measureUnit.ounce * it.customValue * (percent / 100) * weight
            PurityResult(it.purity, purityPrice, weight)
        }
        return ScrapCalculationResult(
            metal,
            price,
            percent,
            measureUnit,
            currency,
            exchangeRate,
            priceInCurrency,
            priceInCurrencyAdjusted,
            purityResults,
            metalPriceTimestamp?.inTimeZone(appSettingsRepository.timeZone),
            currencyRateTimestamp?.inTimeZone(appSettingsRepository.timeZone),
        )
    }
//    val purity: Float = 0.375f
//    val goldAccountabilityValue: Float = 1f // 0-1(0-100%)
//    val pricePerOunce: Float = 2006.1f
//    val exchangeRate: Float = 1.3476f
//    val convPricePerOunce: Float = pricePerOunce * exchangeRate
//    val convGoldPrice: Float =
//        convPricePerOunce * ScrapCalc.GRAM_TO_OUNCE // Formulas.convertOuncePriceTo(convPricePerOunce, unitPosition);
//    val pricePerUnit: Float = convGoldPrice * purity * goldAccountabilityValue
//    val weight: Float = 1f
//    val result = pricePerUnit * weight
//
//    val rr =
//        (((pricePerOunce * exchangeRate) * ScrapCalc.GRAM_TO_OUNCE) * purity * goldAccountabilityValue) * weight
}
