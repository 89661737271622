package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.serialization.Serializable

@Serializable
data class MetalAdjustment(
    val metal: Metal,
    val value: Float = 0f,
    val type: AdjustmentType = AdjustmentType.Points
)

@Serializable
data class ExchangeRateAdjustment(
    val currency: Currency,
    val value: Float = 0f,
    val type: AdjustmentType = AdjustmentType.Points
)

@Serializable
data class CurrencyAdjustments(
    val currency: Currency,
    val exchangeRateAdjustment: ExchangeRateAdjustment,
    val metalAdjustments: List<MetalAdjustment>
) {
    companion object {
        fun default(currency: Currency) = CurrencyAdjustments(
            currency,
            ExchangeRateAdjustment(currency),
            listOf(
                MetalAdjustment(Metal.Gold),
                MetalAdjustment(Metal.Silver),
                MetalAdjustment(Metal.Platinum)
            )
        )
    }
}
