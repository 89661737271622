package com.kitco.scrapit.style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.width

object ComponentsStylesheet : StyleSheet() {
    val ButtonBase by style {
        padding(4.px, 15.px)
        backgroundImage("linear-gradient(#F9F9F9, #B1B1B1)")
        borderRadius(5.px)
        borderWidth(0.px)
    }

    val ButtonDark by style {
        padding(5.px, 15.px)
        backgroundImage("linear-gradient(rgb(191 189 189), rgb(110, 110, 110))")
        borderRadius(5.px)
        borderWidth(0.px)
    }
    val ButtonDarkSm by style {
        padding(2.px, 12.px)
        backgroundImage("linear-gradient(rgb(191 189 189), rgb(110, 110, 110))")
        borderRadius(5.px)
        borderWidth(0.px)
        color(Color.white)
    }
    val TableHeaderContainer by style {
        paddingTop(10.px)
        paddingBottom(10.px)
        width(100.percent)
        borderRadius(7.px, 7.px, 0.px, 0.px)
        color(Color.black)
        backgroundImage("linear-gradient(#F9F9F9, #B1B1B1)")
    }

    val TitleTableHeader by style {
        width(20.percent)
        fontSize(20.px)
        fontWeight("500")
        paddingLeft(10.px)
    }

    val EditBtnSection by style {
        display(DisplayStyle.Flex)
        fontSize(16.px)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.End)
        paddingRight(10.px)
    }

    val DescriptionHeaderContainer by style {
        width(100.percent)
        height(44.px)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignItems(AlignItems.Center)
        backgroundColor(rgba(232, 232,232, 1))
    }

    val DescriptionSquareStyle by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.Center)
        fontSize(14.px)
        fontWeight("bold")
    }
    val DescriptionSquareLastStyle by style {
        width(50.percent)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        fontSize(13.px)
    }
    val DialogRowStyle by style {
        height(30.px)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        boxSizing("border-box")
    }
    val FlagsSection by style {
        position(Position.Absolute)
        backgroundImage("url('/ico_country_sprite.png')")
        backgroundRepeat("no-repeat")
        backgroundSize("cover")
        width(35.px)
        height(420.px)
        left(0.px)
    }
    val CurrencyContainer by style {
        overflowY("scroll")
        boxSizing("border-box")
    }
    val currencyItem by style {
        fontSize(16.px)
        padding(3.px, 10.px, 3.px, 10.px)
        cursor("pointer")
    }
    val currencyItem_2 by style {
        fontSize(16.px)
        padding(3.px, 10.px, 3.px, 10.px)
        cursor("pointer")
    }
    val calculatorHeaderIcon by style {
        boxSizing("border-box")
        backgroundImage("url('/ico_mainform_trigger.png')")
        backgroundRepeat("no-repeat")
        backgroundSize("cover")
        width(20.px)
        height(20.px)
    }
    val timeZoneCheckbox by style {
        height(22.px)
        width(22.px)
        borderRadius(50.percent)
        backgroundColor(Color.black)
        marginRight(5.px)
        backgroundImage("radial-gradient(#b5b5b3, #000000")
    }
    val timeZoneCheckboxActive by style {
        height(10.px)
        width(10.px)
        borderRadius(50.percent)
        //backgroundColor(rgb(244, 116, 2))
        backgroundImage("radial-gradient(#efa342, #f58342")
    }
    val timeZoneItem by style {
        width(33.percent)
        cursor("pointer")
        fontSize(15.px)
        boxSizing("border-box")
    }
    val mobileMenuWrap by style {
        height(60.px)
        width(100.percent)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.SpaceBetween)
        backgroundImage("linear-gradient(#e8e8e8, #B1B1B1)")
    }
    val menuItem by style {
        width(25.percent)
        height(100.percent)
        cursor("pointer")
        display(DisplayStyle.Flex)
        flexGrow(1)
    }
}