package com.kitco.metalynxscrapit.shared.data.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MultipleMetalsDto(@SerialName("PreciousMetals") private val metals: MetalsDto) {
    val list get() = metals.metals
}

@Serializable
data class SingleMetalDto(@SerialName("PreciousMetals") private val metal: MetalDto) {
    val item get() = metal.metal
}

@Serializable
data class MetalDto(@SerialName("PM") val metal: PreciousMetalDto)

@Serializable
data class MetalsDto(@SerialName("PM") val metals: List<PreciousMetalDto>)

@Serializable
data class PreciousMetalDto(
    @SerialName("Symbol") val symbol: String,
    @SerialName("Unit") val unit: String,
    @SerialName("Currency") val currency: String,
    @SerialName("High") val high: Float,
    @SerialName("Mid") val mid: Float,
    @SerialName("Change") val change: Float,
    @SerialName("Timestamp") val timestamp: String,
    @SerialName("ChangePercentage") val changePercentage: Float,
    @SerialName("Low") val low: Float,
    @SerialName("Ask") val ask: Float,
    @SerialName("Bid") val bid: Float
)
