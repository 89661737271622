package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.kitco.scrapit.components.MobileMenuBar
import com.kitco.scrapit.moreViewModel
import com.kitco.scrapit.style.AppStylesheet
import dev.icerock.moko.resources.desc.StringDesc
import org.jetbrains.compose.web.dom.Div

enum class WidgetStatusType {
    LIVE_MARKET,
    CONTACT_US,
    MODIFY,
    MORE,
    NONE,
}

@Composable
fun WrapperMainView() {
    val widgetStatus = remember { mutableStateOf(WidgetStatusType.NONE) }
    val transitionStatus = remember { mutableStateOf(false) }
    val editMode = remember { mutableStateOf(false) }

    Div(
        attrs = { classes(AppStylesheet.mainContainer) }
    ) {
        Div(
            attrs = { classes(AppStylesheet.wrapperView) }
        ) {
            val state by moreViewModel.state.collectAsState()
            StringDesc.localeType = StringDesc.LocaleType.Custom(state.language.code)
            key(state.language.code) {
                MenuBarView(widgetStatus, transitionStatus)
                NavbarView(widgetStatus, transitionStatus)
                MainView(widgetStatus, transitionStatus, editMode)
                MobileMenuBar(widgetStatus, transitionStatus)
            }
        }
    }
}
