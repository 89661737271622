package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import com.kitco.scrapit.calculatorViewModel
import com.kitco.scrapit.components.ButtonDark
import com.kitco.scrapit.isDecimalNumber
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun TableHeaderView(
    state: ScrapCalculationTableUiState.Header,
    editMode: MutableState<Boolean>
) {
    Div(
        attrs = {
            classes(ComponentsStylesheet.TableHeaderContainer, CommonStyle.flexBetween)
        }
    ) {
        Span(
            attrs = {
                classes(ComponentsStylesheet.TitleTableHeader)
            }
        ) {
            Text(stringResource(state.title))
        }

        Span({
            classes(CommonStyle.calcItemFontSz)
            style {
                fontSize(14.px)
                fontWeight("bold")
            }
        }) {
            Text("USD $")
            TextInput(value = state.priceInput.value) {
                classes(CommonStyle.calcInputHeader)
                style {
                    marginRight(5.px)
                    marginLeft(5.px)
                    borderRadius(5.px)
                }
                onInput {
                    if (!it.value.isDecimalNumber) return@onInput
                    calculatorViewModel.onMetalPriceChange(state.metal, it.value)
                }
            }
            Text("/${stringResource(SharedRes.strings.ounce_short)}")
        }

        Span(
            attrs = {
                classes(ComponentsStylesheet.EditBtnSection)
                style { width(40.percent) }
            }
        ) {
            if (state.timestamp != null) Span(
                attrs = {
                    classes(CommonStyle.flexCentered, CommonStyle.calcItemFontSz)
                    style { paddingRight(5.px) }
                }
            ) {
                Text(state.timestamp!!)
            }
            ButtonDark(stringResource(SharedRes.strings.edit), 16) {
                editMode.value = true
            }
        }
    }
}
