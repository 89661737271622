package com.kitco.metalynxscrapit.shared.presentation.calculator.state

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import dev.icerock.moko.resources.ImageResource

data class CurrencyUiState(
    val currency: Currency,
    val icon: ImageResource,
    val title: String,
    val selected: Boolean = false
)
