package com.kitco.metalynxscrapit.shared.data.repository.settings

import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import com.kitco.metalynxscrapit.shared.domain.model.Language
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.serializedValue

class AppSettingsRepository(private val settings: Settings) {

    var language: Language by settings.serializedValue(
        Language.serializer(),
        SettingsKey.Language.name,
        Language.English
    )

    var timeZone: AppTimeZone by settings.serializedValue(
        AppTimeZone.serializer(),
        SettingsKey.TimeZone.name,
        AppTimeZone.Local
    )
}
