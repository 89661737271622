package com.kitco.metalynxscrapit.shared.data.datasource

import com.kitco.metalynxscrapit.shared.data.repository.settings.SettingsKey
import com.kitco.metalynxscrapit.shared.data.repository.settings.londonFixKey
import com.kitco.metalynxscrapit.shared.domain.model.CurrencyRate
import com.kitco.metalynxscrapit.shared.domain.model.LondonFix
import com.kitco.metalynxscrapit.shared.domain.model.MetalPrice
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.decodeValueOrNull
import com.russhwolf.settings.serialization.encodeValue
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.datetime.LocalDate
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer

class PricesLocalDataSource(private val settings: Settings) {

    suspend fun getPreciousMetals(): List<MetalPrice>? = getList(
        MetalPrice.serializer(),
        SettingsKey.PreciousMetals.name
    )

    suspend fun setPreciousMetals(preciousMetals: List<MetalPrice>) = setList(
        MetalPrice.serializer(),
        SettingsKey.PreciousMetals.name,
        preciousMetals
    )

    suspend fun getCurrencies(): List<CurrencyRate>? = getList(
        CurrencyRate.serializer(),
        SettingsKey.Currencies.name
    )

    suspend fun setCurrencies(currencies: List<CurrencyRate>) = setList(
        CurrencyRate.serializer(),
        SettingsKey.Currencies.name,
        currencies
    )

    suspend fun getLondonFix(date: LocalDate): List<LondonFix>? = getList(
        LondonFix.serializer(),
        date.londonFixKey
    )

    suspend fun setLondonFix(date: LocalDate, londonFix: List<LondonFix>) = setList(
        LondonFix.serializer(),
        date.londonFixKey,
        londonFix
    )

    private suspend fun <T> getList(serializer: KSerializer<T>, key: String): List<T>? =
        withContext(Dispatchers.Default) {
            settings.decodeValueOrNull(
                ListSerializer(serializer),
                key
            )
        }

    private suspend fun <T> setList(serializer: KSerializer<T>, key: String, value: List<T>) =
        withContext(Dispatchers.Default) {
            settings.encodeValue(
                ListSerializer(serializer),
                key,
                value
            )
        }
}
