package com.kitco.metalynxscrapit.shared.presentation.calculator

import com.kitco.metalynxscrapit.shared.domain.model.Currency
import com.kitco.metalynxscrapit.shared.domain.model.ExchangeRateAdjustment
import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.MetalAdjustment
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.domain.model.PuritySettings
import com.kitco.metalynxscrapit.shared.domain.model.ScrapCalculationResult
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.CurrencyUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ExchangeRateAdjustmentUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.MeasureUnitUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.MetalAdjustmentUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.PurityItemUiState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import com.kitco.metalynxscrapit.shared.presentation.common.InputState
import com.kitco.metalynxscrapit.shared.presentation.common.formatted
import com.kitco.metalynxscrapit.shared.presentation.common.icon
import com.kitco.metalynxscrapit.shared.presentation.common.shortTitle
import com.kitco.metalynxscrapit.shared.presentation.common.symbol
import com.kitco.metalynxscrapit.shared.presentation.common.title
import com.kitco.metalynxscrapit.shared.presentation.common.toString

fun Currency.toUiState() = CurrencyUiState(
    currency = this,
    icon = icon,
    title = name
)

fun Currency.toSelectorUiState() = Currency.entries.map {
    it.toUiState().copy(selected = it == this)
}

fun MeasureUnit.toUiState() = MeasureUnitUiState(this, title)

fun MeasureUnit.toSelectorUiState() = MeasureUnit.entries.map {
    it.toUiState().copy(selected = it == this)
}

fun ScrapCalculationResult.toUiState(
    customPrice: String? = null,
    customRate: String? = null,
    customPercent: String? = null,
    customWeight: Triple<Metal, Purity, String>? = null
): List<ScrapCalculationTableUiState> {
    val priceInput = InputState(customPrice ?: if (price == 0f) "" else price.toString(2))
    val header = ScrapCalculationTableUiState.Header(
        metal = metal,
        title = metal.title,
        priceInput = priceInput,
        timestamp = metalPriceTimestamp?.formatted()
    )

    val rateInput =
        InputState(customRate ?: if (currencyRate == 0f) "" else currencyRate.toString(2))
    val exchangeRate =
        if (currency == Currency.USD) null
        else ScrapCalculationTableUiState.ExchangeRate(
            currency = currency,
            currencyCode = currency.name,
            rateInput = rateInput,
            icon = currency.icon,
            unadjustedRate = "${currency.name} $$priceInCurrency",
            timestamp = currencyRateTimestamp?.formatted()
        )

    val description = ScrapCalculationTableUiState.Description(
        metal = metal,
        percent = customPercent ?: if (percent == 0f) "" else percent.toString(2),
        marketValue = "${currency.name} ${priceInCurrencyAdjusted.toString(2)}"
    )

    val items = purityResults.map {
        val customWeightValue = customWeight
            ?.takeIf { triple -> metal == triple.first && it.purity == triple.second }
            ?.third
        ScrapCalculationTableUiState.PurityItem(
            metal = metal,
            purity = it.purity,
            purityTitle = it.purity.title,
            weight = customWeightValue ?: if (it.weight == 0f) "" else it.weight.toString(0, true),
            measureUnit = measureUnit.shortTitle,
            result = "${currency.name} ${it.price.toString(2)}"
        )
    }

    return listOfNotNull(header, exchangeRate, description, *items.toTypedArray())
}

fun MetalAdjustment.toUiState(): MetalAdjustmentUiState =
    MetalAdjustmentUiState(
        metal = metal,
        title = metal.title,
        adjustmentType = type,
        currency = Currency.USD.name,
        adjustment = if (value == 0f) "" else value.toString(),
        adjustedPrice = ""
    )

fun MetalAdjustmentUiState.toDomain() = MetalAdjustment(
    metal = metal,
    value = adjustment.toFloatOrNull() ?: 0f,
    type = adjustmentType
)

fun ExchangeRateAdjustment.toUiState(): ExchangeRateAdjustmentUiState =
    ExchangeRateAdjustmentUiState(
        currency = currency,
        title = currency.name,
        adjustmentType = type,
        adjustment = if (value == 0f) "" else value.toString(),
        adjustedRate = ""
    )

fun ExchangeRateAdjustmentUiState.toDomain() = ExchangeRateAdjustment(
    currency = currency,
    value = adjustment.toFloatOrNull() ?: 0f,
    type = adjustmentType
)

fun PuritySettings.toUiState() = PurityItemUiState(
    metal = metal,
    purity = purity,
    enabled = enabled,
    purityValue = customValue.toString(4),
    purityPercent = "${(customValue * 100).toString(2)}%",
    description = purity.title,
    weight = weight
)

fun PurityItemUiState.toDomain() = PuritySettings(
    metal = metal,
    purity = purity,
    enabled = enabled,
    customValue = purityValue.toFloatOrNull() ?: purity.purity,
    weight = weight
)
