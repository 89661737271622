package com.kitco.metalynxscrapit.shared.data.api

import io.ktor.client.HttpClient
import io.ktor.client.request.get

class FeedbackApi(private val httpClient: HttpClient) {

    suspend fun sendFeedback(sender: String, subject: String, message: String) =
        httpClient.get("postman/delivery") {
            url {
                parameters.append("app", "scrap_calculator")
                parameters.append("sender", sender)
                parameters.append("subject", subject)
                parameters.append("message", message)
            }
        }
}
