package com.kitco.metalynxscrapit.shared.domain.interactor

import com.kitco.metalynxscrapit.shared.data.repository.PricesRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.AppSettingsRepository
import com.kitco.metalynxscrapit.shared.data.repository.settings.LiveMarketSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.model.LiveMetalPrice
import com.kitco.metalynxscrapit.shared.domain.model.Metal

class LiveMetalPriceInteractor(
    private val pricesRepository: PricesRepository,
    private val settingsRepository: LiveMarketSettingsRepository,
    private val appSettingsRepository: AppSettingsRepository
) {

    suspend operator fun invoke(metal: Metal): LiveMetalPrice {
        val measureUnit = settingsRepository.getMarketPriceMeasureUnit(metal)
        val currency = settingsRepository.getMarketPriceCurrency(metal)

        val metalPrice = pricesRepository.getLivePreciousMetal(
            metal,
            currency,
            measureUnit
        )

        return LiveMetalPrice(
            metalPrice.timestamp.inTimeZone(appSettingsRepository.timeZone),
            metalPrice.bid,
            metalPrice.ask,
            metalPrice.high,
            metalPrice.low,
            metalPrice.change,
            metalPrice.changePercentage,
            measureUnit,
            currency
        )
    }
}
