package com.kitco.scrapit.style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.media
import org.jetbrains.compose.web.css.mediaMaxWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.width

object AppStylesheet : StyleSheet() {
    init {
        "*" style {
            color(rgb(54, 54, 54))
        }
    }

    val mainContainer by style {
        width(100.percent)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        backgroundColor(rgb(238, 238, 238))
        boxSizing("border-box")
        color(rgb(54, 54, 54))
        property("font-family", "Arial, Helvetica, sans-serif")
    }

    val mainContainerInner by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
        width(100.percent)
        marginBottom(40.px)
        marginTop(10.px)
        backgroundColor(Color.white)
        padding(10.px, 20.px, 20.px, 20.px)
        boxSizing("border-box")
    }

    val wrapperView by style {
        width(1000.px)
        boxSizing("border-box")

    }

    val menuContainer by style {
        width(100.percent)
        height(60.px)
        position(Position.Relative)
        backgroundImage("linear-gradient(#efa342, #f58342")
        boxSizing("border-box")
    }

    val menuBarWrapper by style {
        width(100.percent)
        height(100.percent)
        position(Position.Absolute)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.SpaceBetween)
        alignItems(AlignItems.Center)
        padding(0.px, 20.px)
        boxSizing("border-box")
    }

    val calculatorContainer by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
        width(100.percent)
    }

    val wrapperCalculator by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        width(100.percent)

    }

    val calculatorSection by style {
        width(60.percent)
        flexGrow(1)
        media(mediaMaxWidth(992.px)) {
            self style {
                width(100.percent)
            }
        }
    }

    val widgetsSection by style {
        width(315.px)
        paddingTop(10.px)
        marginLeft(20.px)
        boxSizing("border-box")
    }

    val widgetsSectionMobile by style {
        width(100.percent)
        boxSizing("border-box")
        position(Position.Fixed)
        height(800.px)
        bottom((-750).px)
    }
    val hideMobile by style {
        bottom((-700).px)
    }
    val showMobile by style {
        bottom(0.px)
    }

    val navbarContainer by style {
        width(100.percent)
        height(60.px)
        padding(0.px, 18.px)
        backgroundColor(Color.white)
        boxSizing("border-box")
    }

    val titleApp by style {
        fontSize(24.px)
        color(Color.white)
        paddingLeft(4.px)
    }
    val calculatorHeader by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.Center)
        fontSize(14.px)
        fontWeight("bold")
    }
    val calculatorHeaderLeft by style {
        width(50.percent)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        fontSize(13.px)
    }
}