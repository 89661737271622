package com.kitco.metalynxscrapit.shared.presentation.more

import com.kitco.metalynxscrapit.shared.data.repository.settings.AppSettingsRepository
import com.kitco.metalynxscrapit.shared.domain.model.AppTimeZone
import com.kitco.metalynxscrapit.shared.domain.model.Language
import com.kitco.metalynxscrapit.shared.presentation.CoroutineViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update

class MoreViewModel(
    private val settingsRepository: AppSettingsRepository
) : CoroutineViewModel() {

    private val _state = MutableStateFlow(createInitialState())
    val state: StateFlow<MoreUiState> = _state.asStateFlow()

    fun onLanguageChange(language: Language) {
        settingsRepository.language = language
        _state.update {
            it.copy(language = language)
        }
    }

    fun onTimeZoneChange(timeZone: AppTimeZone) {
        settingsRepository.timeZone = timeZone
        _state.update {
            it.copy(timeZone = timeZone)
        }
    }

    private fun createInitialState() = MoreUiState(
        settingsRepository.language,
        settingsRepository.timeZone
    )
}
