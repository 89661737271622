package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.serialization.Serializable

@Serializable
data class PuritySettings(
    val metal: Metal,
    val purity: Purity,
    val enabled: Boolean = true,
    val customValue: Float = purity.purity,
    val weight: Float = 1f
)
