package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.scrapit.handlers.handleWidgetMove
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.WidgetStyleSheet
import com.kitco.scrapit.view.WidgetStatusType
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun TitleBarWidget(title: String, widgetStatus: MutableState<WidgetStatusType>, transitionStatus: MutableState<Boolean>) {
    Div (
        attrs = {
            classes(WidgetStyleSheet.titleBarWidget, CommonStyle.flexBetween)
        }
    ) {
        Span (
            attrs = {
                classes(WidgetStyleSheet.titleStyle)
            }
        ) {
            Text(title)
        }

        Button (
            attrs = {
                classes(WidgetStyleSheet.titleBtn)
                onClick { handleWidgetMove(widgetStatus, WidgetStatusType.NONE, transitionStatus) }
            }
        ) {
        }
    }
}