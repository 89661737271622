package com.kitco.scrapit.view

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.ScrapCalculationTableUiState
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun TableView(
    state: List<ScrapCalculationTableUiState>,
    editMode: MutableState<Boolean>
) {
    Div({
        style {
            width(100.percent)
            border(1.px, LineStyle.Solid, rgba(180, 180, 180, 1))
            borderWidth(1.px)
            borderRadius(7.px)
            marginTop(10.px)
            fontFamily("Arial")
        }
    }) {
        state.forEach {
            when (it) {
                is ScrapCalculationTableUiState.Header -> TableHeaderView(it, editMode)
                is ScrapCalculationTableUiState.ExchangeRate -> { /*TODO*/ }
                is ScrapCalculationTableUiState.Description -> TableDescriptionHeaderView(it)
                is ScrapCalculationTableUiState.PurityItem -> CalculatorItemView(it)
            }
        }
    }
}
