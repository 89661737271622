package com.kitco.metalynxscrapit.shared.presentation.feedback

import com.kitco.metalynxscrapit.shared.SharedRes
import dev.icerock.moko.resources.StringResource

data class FeedbackUiState(
    val email: String = "",
    val message: String = "",
    val category: Category = Category.RefiningServicesQuestion
)

enum class Category(val title: StringResource) {
    RefiningServicesQuestion(SharedRes.strings.feedback_category_questions),
    AppProblem(SharedRes.strings.feedback_category_problems_app),
    DataProblem(SharedRes.strings.feedback_category_problems_data),
    Suggestion(SharedRes.strings.suggestions),
    Other(SharedRes.strings.feedback_category_other)
}
