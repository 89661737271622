package com.kitco.metalynxscrapit.shared.presentation.calculator.state

import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import dev.icerock.moko.resources.StringResource

data class EditPuritiesUiState(
    val gold: List<PurityItemUiState>,
    val silver: List<PurityItemUiState>,
    val platinum: List<PurityItemUiState>
)

data class PurityItemUiState(
    val metal: Metal,
    val purity: Purity,
    val enabled: Boolean,
    val purityValue: String,
    val purityPercent: String,
    val description: StringResource,
    val weight: Float
)
