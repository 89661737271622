package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.serialization.Serializable

@Serializable
enum class MeasureUnit(val ounce: Float) {
    Ounce(1f),
    Gram(0.032150745f),
    Kilo(32.150745f),
    DWT(0.05f),
    TOLA(0.375f),
    TAEL(1.2152778f)
}
