package com.kitco.metalynxscrapit.shared.data.mapper

import com.kitco.metalynxscrapit.shared.domain.model.Metal
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.toLocalDate
import kotlinx.datetime.toLocalTime

fun String.toLocalDateTime(): LocalDateTime {
    val dateTime = this.split(" ")
    val date = dateTime.first().toLocalDate()
    val time = dateTime[1].toLocalTime()
    return LocalDateTime(date, time)
}

fun String.toMetal() = Metal.entries.first { it.code == this }
