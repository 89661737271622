package com.kitco.metalynxscrapit.shared.presentation.common

import dev.icerock.moko.resources.ImageResource

data class DropdownState(
    val selectedItem: String,
    val items: List<DropDownItemState>,
    val leadingIcon: ImageResource? = null
)

data class DropDownItemState(
    val id: Int,
    val title: String,
    val leadingIcon: ImageResource? = null
)
