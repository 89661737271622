package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.datetime.LocalDateTime

data class ScrapCalculationResult(
    val metal: Metal,
    val price: Float,
    val percent: Float,
    val measureUnit: MeasureUnit,
    val currency: Currency,
    val currencyRate: Float,
    val priceInCurrency: Float,
    val priceInCurrencyAdjusted: Float,
    val purityResults: List<PurityResult>,
    val metalPriceTimestamp: LocalDateTime? = null,
    val currencyRateTimestamp: LocalDateTime? = null
)

data class PurityResult(
    val purity: Purity,
    val price: Float,
    val weight: Float
)
