package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import dev.icerock.moko.resources.ImageResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

enum class TypeIcon {
    BASE,
    NONE
}

@Composable
fun ButtonBase(
    title: String,
    titleSize: Number,
    typeIcon: TypeIcon,
    leadingIcon: ImageResource? = null,
    onClick: () -> Unit
) {
    Button(
        attrs = {
            classes(ComponentsStylesheet.ButtonBase)
            onClick { onClick() }
        }
    ) {
        Div({
            style {
                fontSize(titleSize.px)
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center)
            }
        }) {
            if (leadingIcon != null) {
                Img(leadingIcon.fileUrl, attrs = {
                    style { height(20.px) }
                })
            }
            Div({ style { marginLeft(5.px) } }) {
                Text(title)
            }
        }
        if (typeIcon == TypeIcon.BASE) {
            Div(
                attrs = {
                    classes(CommonStyle.imageBtnContainer)
                }
            )
        }
    }
}
