package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import com.kitco.metalynxscrapit.shared.domain.model.Purity
import com.kitco.metalynxscrapit.shared.presentation.calculator.state.PurityItemUiState
import com.kitco.scrapit.editPuritiesViewModel
import com.kitco.scrapit.isDecimalNumber
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.view.DividerVertical
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.CheckboxInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun CalculatorItemViewEdit(state: PurityItemUiState) {
    Div({
        style {
            width(100.percent)
            height(1.px)
            backgroundColor(rgba(180, 180, 180, 1))
        }
    }) { }

    Div({
        classes(CommonStyle.calcItemFontSz)
        style {
            width(100.percent)
            height(48.px)
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            alignItems(AlignItems.Center)
            fontSize(16.px)
            boxSizing("border-box")
        }
    }) {
        Span({
            classes(CommonStyle.calcItemFontSz)
            style {
                width(20.percent)
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.End)
                paddingLeft(10.px)
            }
        }) {
            CheckboxInput(
                checked = state.enabled,
                attrs = {
                    classes(CommonStyle.checkBoxStyle)
                    onChange {
                        editPuritiesViewModel.onCheckedChange(state.metal, state.purity)
                    }
                }
            )
            if (state.enabled) {
                Label(
                    attrs = {
                        classes(CommonStyle.customCheckBoxChecked)
                    }
                )
            } else {
                Label(
                    attrs = {
                        classes(CommonStyle.customCheckBox)
                    }
                )
            }

        }
        DividerVertical()
        Span(
            attrs = {
                classes(CommonStyle.flexBetween)
                style {
                    width(30.percent)
                    paddingLeft(10.px)
                    paddingRight(10.px)
                }
            }
        ) {
            Span({
                classes(CommonStyle.calcItemFontSz)
                style {
                    paddingRight(10.px)
                    width(40.percent)
                    display(DisplayStyle.Block)
                }
            }) {
                Text(state.purityPercent)
            }
            TextInput(
                value = state.purityValue,
                attrs = {
                    classes(CommonStyle.textInputStyle, CommonStyle.calcItemFontSz)
                    style {
                        width(60.percent)
                        textAlign("center")
                    }
                    onInput {
                        if (!it.value.isDecimalNumber) return@onInput
                        editPuritiesViewModel.onPurityChange(state.metal, state.purity, it.value)
                    }
                }
            )
        }
        DividerVertical()
        Span({
            style {
                width(50.percent)
                height(100.percent)
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.Center)
                backgroundColor(rgba(249, 249, 249, 1))
            }
        }) {
            val text = if (state.purity == Purity.Platinum.PLATINUM_90) {
                stringResource(
                    state.description,
                    "% "
                )
            } else {
                stringResource(state.description)
            }
            TextInput(
                value = text,
                attrs = {
                    classes(CommonStyle.textInputStyle, CommonStyle.calcItemFontSz)
                    style {
                        width(60.percent)
                        textAlign("center")
                        fontSize(15.px)
                    }
                    onInput {
                        /*TODO*/
                    }
                }
            )
        }
    }
}
