package com.kitco.metalynxscrapit.shared.domain.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class CurrencyRate(
    val currency: Currency,
    val timestamp: Instant,
    val priceToUsd: Float
)
