package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.domain.model.AdjustmentType
import com.kitco.metalynxscrapit.shared.presentation.common.DropDownItemState
import com.kitco.metalynxscrapit.shared.presentation.common.DropdownState
import com.kitco.scrapit.isDecimalNumber
import com.kitco.scrapit.style.CommonStyle
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun ModifyItem(
    title: StringResource,
    adjustment: String,
    dropDownCurrencyText: String,
    adjustmentType: AdjustmentType,
    adjustedValue: String,
    onInput: (String) -> Unit,
    onAdjustmentItemClick: (AdjustmentType) -> Unit
) {
    Div(
        attrs = {
            classes(CommonStyle.flexCentered)
            style {
                height(40.px)
                paddingTop(5.px)
            }
        }
    ) {
        Div({
            style {
                width(20.percent)
                textAlign("start")
                marginLeft(5.px)
                fontSize(14.px)
            }
        }) {
            Text(stringResource(title))
        }
        Div(
            attrs = {
                classes(CommonStyle.flexStart)
                style {
                    width(45.percent)
                }
            }
        ) {
            TextInput(
                value = adjustment,
                attrs = {
                    classes(CommonStyle.textInputStyle)
                    style {
                        width(40.px)
                        height(20.px)
                        borderRadius(5.px)
                        marginRight(5.px)
                        border(1.px, LineStyle.Solid, rgba(180, 180, 180, 1))
                    }
                    onInput {
                        if (!it.value.isDecimalNumber) return@onInput
                        onInput(it.value)
                    }
                }
            )
            Div {
                DropDown(
                    DropdownState(
                        selectedItem = if (adjustmentType == AdjustmentType.Points) dropDownCurrencyText else "%",
                        items = listOf(
                            DropDownItemState(0, dropDownCurrencyText),
                            DropDownItemState(
                                1,
                                stringResource(
                                    SharedRes.strings.adjustment_type_percent,
                                    "% ",
                                    "%)"
                                )
                            )
                        ),
                    ),
                    onItemClick = { onAdjustmentItemClick(AdjustmentType.entries[it.id]) },
                    titleSize = 14
                )
            }
        }
        Div(
            attrs = {
                classes(CommonStyle.flexEnd)
                style {
                    width(35.percent)
                    fontSize(12.px)
                    fontWeight(700)
                    marginRight(5.px)
                }
            }
        ) {
            Text(adjustedValue)
        }
    }
}
