package com.kitco.metalynxscrapit.shared.presentation.calculator.state

import com.kitco.metalynxscrapit.shared.domain.model.MeasureUnit
import dev.icerock.moko.resources.StringResource

data class MeasureUnitUiState(
    val measureUnit: MeasureUnit,
    val title: StringResource,
    val selected: Boolean = false
)
