package com.kitco.scrapit.components

import androidx.compose.runtime.Composable
import com.kitco.metalynxscrapit.shared.SharedRes
import com.kitco.metalynxscrapit.shared.domain.model.Metal
import com.kitco.scrapit.editPuritiesViewModel
import com.kitco.scrapit.style.CommonStyle
import com.kitco.scrapit.style.ComponentsStylesheet
import com.kitco.scrapit.view.DividerVertical
import dev.icerock.moko.resources.compose.stringResource
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun TableDescriptionHeaderEdit(metal: Metal) {
    Div(
        attrs = {
            classes(ComponentsStylesheet.DescriptionHeaderContainer)
        }
    ) {
        Span(
            attrs = {
                classes(ComponentsStylesheet.DescriptionSquareStyle, CommonStyle.width_20)
                style {
                    paddingLeft(10.px)
                }
            }
        ) {
            Text(stringResource(SharedRes.strings.display_hide))
        }
        DividerVertical()
        Span(
            attrs = {
                classes(ComponentsStylesheet.DescriptionSquareStyle, CommonStyle.width_30)
                style {
                    alignItems(AlignItems.Center)
                }
            }
        ) {
            Button(
                attrs = {
                    classes(ComponentsStylesheet.ButtonDarkSm, CommonStyle.ml_10)
                    onClick {
                        editPuritiesViewModel.onResetClick(metal)
                    }
                }
            ) {
                Text(stringResource(SharedRes.strings.reset))
            }
        }
        DividerVertical()
        Span(
            attrs = {
                classes(ComponentsStylesheet.DescriptionSquareLastStyle)
            }
        ) {
            Span({
                classes(ComponentsStylesheet.DescriptionSquareStyle, CommonStyle.width_20)
                style {
                    marginLeft(5.px)
                }
            }) {
                Text(stringResource(SharedRes.strings.description))
            }
        }
    }
}
